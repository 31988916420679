import gql from 'graphql-tag';
import apolloClient from '../apollo';
import { solarPlantOverallPower } from '@/helpers/projects';
import Vue from 'vue';
import { PROJECT_REQUEST_FIELDS } from './projectFragment';
import { PROJECT_LIST_REQUEST_FIELDS } from './projectFragmentList';
import { PROJECT_LIST_MOBILE_REQUEST_FIELDS } from './projectFragmentMobileList';
import { PROJECT_PAGINATED_LIST_REQUEST_FIELDS } from './projectFragmentPaginatedList';
import { deepObjectValue } from '@/helpers/object';
import { preparePayloadForGraphQL } from '../helpers/validate';

const PROJECT_POST_FIELDS = gql`
  fragment projectPostFields on Post {
    id
    number
    content
    createdAt
    updatedAt
    user {
      id
      number
      firstname
      lastname
      email
      avatar {
        path
      }
    }
  }
`;

/**
 * State
 */
const defaultProject = () => {
  return {
    id: '_new',
    number: '_new',
    employerProjectNumber: '',
    client: null,
    employer: null,
    measurement: {
      roofType: 'PITCHED_ROOF',
      pitchedRoof: {},
      flatRoof: {},
      commonRoof: {},
      ac: {},
      customer: {},
      completed: [],
    },
    enpalReport: { completed: [] },
    serviceReport: { completed: [] },
    assessmentReport: { completed: [] },

    qualityReport: { completed: [] },

    bricksPlasteredAttachments: [],
    customer: {
      birthdayAt: null,
    },
    installation: {
      electricMeterChangedAt: null,
      operationRegistrationAt: null,
    },
    solarPlant: { netOutputStates: ['L1', 'L2', 'L3'], networkSupplyManagementState: 'NONE' },
    items: [],
    mandateAttachments: [],
    overviewSchematicAttachments: [],
    overviewSchematicAttachmentsGenerated: [],
    openMateboxAttachments: [],
    installedComponentsInMeterCabinetAttachments: [],
    openMeterCabinetPrePostAreaAttachments: [],
    closedMeterCabinetInscribedComponentsAttachments: [],
    closedPreMeterAreaWithPlombsAttachments: [],
    breakthroughsWithSealingsMeterCabinetAttachments: [],
    openHAKAttachments: [],
    closedHAKWithSealAttachments: [],
    photoOfWallboxAttachments: [],
    accessCardsOfWallboxAttachments: [],
    circuitBreakerAndSafetySwitchForWallboxAttachments: [],
    cablingBetweenWallboxAndDistributionAttachments: [],
    acceptanceProtocolCustomerSignatureAttachments: [],
    warrantyQCellsAttachments: [],
    cableManagementBetweenMateboxAndMeterCabinetAttachments: [],
    variousBreakthroughsWithPossibleSealingsAttachments: [],
    solarModuleTypePlateAttachments: [],
    reportAttachments: [],
    layoutPlanAttachments: [],
    projectDescriptionAttachments: [],
    installationImageAttachments: [],
    installationTeamDocumentationAttachments: [],
    netRegistrationAttachments: [],
    netRegistrationAttachmentsGenerated: [],
    operationRegistrationAttachments: [],
    operationRegistrationImageAttachments: [],
    operationRegistrationAttachmentsGenerated: [],
    miscAttachments: [],
    measurementAttachments: [],
    teamDCAttachments: [],
    roofAttachments: [],
    roofTileAttachments: [],
    meterCabinetAttachments: [],
    teamACAttachments: [],
    damageAttachments: [],
    netRegistrationRequirements: [],
    operationRegistrationRequirements: [],
    projectState: 'PROJECT_STATE_NEW',
    projectTypeState: 'NEW_PROJECT',
    askEmployerCategoryState: null,
    mandateState: 'MISSING',
    layoutPlanState: 'MISSING',
    projectDescriptionState: 'MISSING',
    installationImagesState: 'MISSING',
    installationTeamDocumentationState: 'MISSING',
    netRegistrationRequirementsFullfilledState: 'NO',
    operationRegistrationRequirementsFullfilledState: 'NO',
    itemPlanningFinishedState: 'NO',
    netRegistrationAttachmentsBuildState: 'NOT_STARTED',
    operationRegistrationAttachmentsBuildState: 'NOT_STARTED',
    projectOfferAttachmentsBuildState: 'NOT_STARTED',
    consumablesListAttachmentsBuildState: 'NOT_STARTED',
    reportAttachmentsBuildState: 'NOT_STARTED',
    consumablesOrderedState: 'NO',
    consumablesInStockState: 'NO',
    technicalReviewDescription: '',
    onHoldReason: '',
    accountingDescription: '',
    powerCompanyDescription: '',
    customerDescription: '',
    scaffoldAttachments: [],
    jobSafetyOptionalAttachments: [],
    ballastAttachments: [],
    brickAndSubstructureOptionalAttachments: [],
    brickAndSubstructureAttachments: [],
    solarPanelAttachments: [],
    cableManagementAttachments: [],
    equipotentialBusBarAttachments: [],
    inverterAttachments: [],
    storageAttachments: [],
    electricMeterAttachments: [],
    systemPowerOnAttachments: [],
    electricMeterChangeAttachments: [],
    signatureAttachments: [],
    deliveryAt: null,
    consumablesDeliveredAt: null,
    powerCompanyClearanceAt: null,
    invoiceSentToEmployerAt: null,
    employerPayedAt: null,
    installationTeamPayedAt: null,
    documentationSentToEmployerAt: null,
    completionMessageSentAt: null,
    injectionConfirmationReceivedAt: null,
    operationRegistrationSentAt: null,
    netRegistrationSentAt: null,
    brickCoverWidthAttachments: [],
    correctBrickHandlingAttachments: [],
    roofHookWithEarthingAttachments: [],
    correctCableInputAttachments: [],
    protectedUnderBrickCableRoutingAttachments: [],
    stringPlanAttachments: [],
    allPanelsVisibleAttachments: [],
    panelBottomViewAttachments: [],
    solarPanelsOptionalAttachments: [],
    railOverlapAttachments: [],
    clampAreaAttachments: [],
    trayfDistanceAttachments: [],
    cableAlongFacadeAttachments: [],
    cableBetweenMateboxAndElectricMeterAttachments: [],
    cableManagementOptionalAttachments: [],
    inverterOptionalAttachments: [],
    inverterSerialNumberAttachments: [],
    mateboxSerialNumberAttachments: [],
    storageSerialNumberAttachments: [],
    dongleSerialNumberAttachments: [],
    potentialGroundRodAttachments: [],
    potentialOptionalAttachments: [],
    electricMeterReadingAttachments: [],
    gakAttachments: [],
    screenshotDeviceFlow: [],
    screenshotModeOfOperation: [],
    netConnectionTerms: [],
    monitoringOptionalAttachments: [],
    electricMeterAndGakOptionalAttachments: [],
    measurementResultAttachments: [],
    measurementProtocolInclPhotosAttachments: [],
    measurementsOptionalAttachments: [],
    properSleeveUsedAtRoofPenetrationAttachments: [],
    underlaySealedWellAtCableEntryAttachments: [],
    underlayDamagedOrWaterStainsAttachments: [],
    labelingOfStringLinesAttachments: [],
    pvStringCableCorrectCrossSectionAttachments: [],
    peCableCorrectlySizedAttachments: [],
    cablesProperlySecuredAttachments: [],
    crimpingProfessionallyDoneAttachments: [],
    correctSelectionOfPvConnectorsOnStringCableAttachments: [],
    allPlugConnectionsProfessionallyMadeAttachments: [],
    cablesNeatlyLaidInsideBuildingAttachments: [],
    spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterAttachments: [],
    surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryAttachments: [],
    documentationProjectReportAvailableAttachments: [],
    specialCustomerRequestsDocumentedAttachments: [],
    tileTypeAndWidthDocumentedAttachments: [],
    replacementTileInstalledAttachments: [],
    lightningProtectionPresentAttachments: [],
    substructureTensionFreeCheckedAttachments: [],
    substructureInstalledAsPerReportAttachments: [],
    eachRailSecuredWithMinTwoHooksAttachments: [],
    distanceBetweenHooksMoreThan140cmAttachments: [],
    correctRoofHooksUsedAttachments: [],
    distanceRoofHookToTile35mmAttachments: [],
    minScrewDistanceToRafterEdge24mmAttachments: [],
    roofHookProperlyFixedToRafterAttachments: [],
    overhangObservedAttachments: [],
    moduleFieldProperlyGroundedAttachments: [],
    approvedAuxiliaryRafterUsedAttachments: [],
    rafterWidthMeasuredAndComparedToDocsAttachments: [],
    noOpenOrDamagedTilesAttachments: [],
    noShiftedOrIncorrectlyLaidTilesAttachments: [],
    tilesProperlyCleanedAttachments: [],
    correctPanHeadScrewsUsedAttachments: [],
    minRailOverhangAfterEndClamp20mmAttachments: [],
    distancesToRidgeEavesNotLessThan20cmAttachments: [],
    moduleClampAreasObservedAttachments: [],
    modulesNoDamagesDirtShadingAttachments: [],
    moduleClampsProperlyTightenedAttachments: [],
    thermalSeparationAfter12mModuleFieldAttachments: [],
    breakthroughProperlySealedAttachments: [],
    distanceLightningProtectionToConductivePartsLessThan05mAttachments: [],
    gutterUndamagedAttachments: [],
    notBuiltOverRoofOverhangAttachments: [],
    cableProperlyLaidInChimneyAttachments: [],
    distanceToDisturbingSurfaces10cmObservedAttachments: [],
    cableEntryWithReplacementPlateAndFlexedTileAttachments: [],
    cablesDoNotRestOnTilesAttachments: [],
    cablesLaidProtectedUnderTilesAttachments: [],
    cableEntryOnRoofProperlyDoneAttachments: [],
    cableLaidThroughChimneyAttachments: [],
    cableLaidInProtectiveSleeveAgainstUvRadiationAttachments: [],
    peCableProperlySecuredAttachments: [],
    documentationProjectReportPresentAttachments: [],
    customerSpecialRequestsConsideredAttachments: [],
    flatRooflightningProtectionPresentAttachments: [],
    properGroundingFlatRoofSystemAttachments: [],
    ballastingAccordingToProjectReportAttachments: [],
    minimumModuleBearingAreaMaintainedAttachments: [],
    qFlatProperlyConnectedAttachments: [],
    distanceToDisturbingSurfacesMaintainedAttachments: [],
    flatRoofmoduleClampsProperlyTightenedAttachments: [],
    modulesNoDamageDirtShadingAttachments: [],
    typeOfCableProtectionOutdoorsAttachments: [],
    correctSelectionOfPvConnectorsAttachments: [],
    cablesDoNotRestOnRoofAttachments: [],
    cableEntryDoneProperlyAttachments: [],
    cablesProtectedFromUvAttachments: [],
    pvStringCableProperlySizedAttachments: [],
    stringCableLabelingAttachments: [],
    peCableProperlySizedAttachments: [],
    flatRoofcablesProperlySecuredAttachments: [],
    crimpingProperlyDoneAttachments: [],
    pvSystemLabeledInOrAtDistributionBoardAttachments: [],
    testReportAvailableWithAllMeasurementsAttachments: [],
    rcdTypeB300maAvailableInMeterAreaAttachments: [],
    stringPlanFiledInMeterCabinetAttachments: [],
    safetyDistancesMaintainedAttachments: [],
    correctLocationForSystemAttachments: [],
    correctLocationForWallboxAttachments: [],
    customerRequestsDocumentedAttachments: [],
    slsInstalledAttachments: [],
    componentsDamagedAttachments: [],
    crimpingDoneProperlyAttachments: [],
    ussWiredProperlyInCabinetAttachments: [],
    ussBoxWiredProperlyAttachments: [],
    bendingRadiiMaintainedAttachments: [],
    correctWiringOfMateboxAttachments: [],
    correctWiringOfBatteryAttachments: [],
    allComponentsProperlyGroundedAttachments: [],
    correctColorCodingAndLabelingAttachments: [],
    correctWiringOfPvConnectorsAttachments: [],
    cableDuctProperlySizedAndCutAttachments: [],
    nhHakSelectiveToSlsAttachments: [],
    checkNetworkTypeAttachments: [],
    earthRodConnectedToHpasAttachments: [],
    connectionBetweenHpasAndPasProperlyMadeAttachments: [],
    insulationProperlyStrippedAttachments: [],
    polarityCheckAttachments: [],
    stringOpenCircuitVoltageAttachments: [],
    shortCircuitCurrentAttachments: [],
    insulationResistanceAttachments: [],

    projectDeferredState: {
      deferred: 'NO',
      reason: 'MISC',
      description: '',
      comment: '',
    },
    technicalReview: {},
    posts: [],
    vlink: {},
  };
};

const getDefaultState = () => {
  return {
    projects: [],
    documentStates: [],
    projectStates: [],
    networkSupplyManagementStates: [],
    networkSupplyManagementPermanentRestrictionStates: [],
    networkSupplyManagementXOverStates: [],
    storageCouplingStates: [],
    netOutputStates: [],
    electricMeterMountingStates: [],
    houseFuseStates: [],
    salutations: [],
    installationTeams: [],
    installationTypes: [],
    originalInstallationTypes: [],
    solarSystemTypes: [],
    projectAttachmentTypes: [],
    items: [],
    projectDescriptionState: 'MISSING',
    projectCount: 0,
    isProjectCacheOutdated: false,
    projectCacheActive: false,
    projectsItemsList: [],
    projectsItemsListCount: 0,
  };
};
const state = getDefaultState();

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (project) => {
  // console.log(project.client.name);
  if (project.enpalReport) {
    delete project.enpalReport.__typename;
    project.enpalReport.uploadedSignatureApprovalCustomer =
      project.enpalReport.uploadedSignatureApprovalCustomer &&
      project.enpalReport.uploadedSignatureApprovalCustomer.id
        ? project.enpalReport.uploadedSignatureApprovalCustomer.id
        : null;
    project.enpalReport.uploadedSignatureApprovalTechnician =
      project.enpalReport.uploadedSignatureApprovalTechnician &&
      project.enpalReport.uploadedSignatureApprovalTechnician.id
        ? project.enpalReport.uploadedSignatureApprovalTechnician.id
        : null;
    project.enpalReport.uploadedSignatureOperationCustomer =
      project.enpalReport.uploadedSignatureOperationCustomer &&
      project.enpalReport.uploadedSignatureOperationCustomer.id
        ? project.enpalReport.uploadedSignatureOperationCustomer.id
        : null;
    project.enpalReport.uploadedSignatureOperationTechnician =
      project.enpalReport.uploadedSignatureOperationTechnician &&
      project.enpalReport.uploadedSignatureOperationTechnician.id
        ? project.enpalReport.uploadedSignatureOperationTechnician.id
        : null;
    project.enpalReport.scaffoldAttachment =
      project.enpalReport.scaffoldAttachment && project.enpalReport.scaffoldAttachment.id
        ? project.enpalReport.scaffoldAttachment.id
        : null;
    project.enpalReport.brickAndUkAttachment_X =
      project.enpalReport.brickAndUkAttachment_X && project.enpalReport.brickAndUkAttachment_X.id
        ? project.enpalReport.brickAndUkAttachment_X.id
        : null;
    project.enpalReport.brickAndUkAttachment_A =
      project.enpalReport.brickAndUkAttachment_A && project.enpalReport.brickAndUkAttachment_A.id
        ? project.enpalReport.brickAndUkAttachment_A.id
        : null;
    project.enpalReport.brickAndUkAttachment_C1 =
      project.enpalReport.brickAndUkAttachment_C1 && project.enpalReport.brickAndUkAttachment_C1.id
        ? project.enpalReport.brickAndUkAttachment_C1.id
        : null;
    project.enpalReport.brickAndUkAttachment_C1_2 =
      project.enpalReport.brickAndUkAttachment_C1_2 &&
      project.enpalReport.brickAndUkAttachment_C1_2.id
        ? project.enpalReport.brickAndUkAttachment_C1_2.id
        : null;
    project.enpalReport.brickAndUkAttachment_C1_3 =
      project.enpalReport.brickAndUkAttachment_C1_3 &&
      project.enpalReport.brickAndUkAttachment_C1_3.id
        ? project.enpalReport.brickAndUkAttachment_C1_3.id
        : null;
    project.enpalReport.brickAndUkAttachment_E =
      project.enpalReport.brickAndUkAttachment_E && project.enpalReport.brickAndUkAttachment_E.id
        ? project.enpalReport.brickAndUkAttachment_E.id
        : null;
    project.enpalReport.brickAndUkAttachment_G =
      project.enpalReport.brickAndUkAttachment_G && project.enpalReport.brickAndUkAttachment_G.id
        ? project.enpalReport.brickAndUkAttachment_G.id
        : null;
    project.enpalReport.brickAndUkAttachment_H =
      project.enpalReport.brickAndUkAttachment_H && project.enpalReport.brickAndUkAttachment_H.id
        ? project.enpalReport.brickAndUkAttachment_H.id
        : null;
    project.enpalReport.solarPanelAttachment_A =
      project.enpalReport.solarPanelAttachment_A && project.enpalReport.solarPanelAttachment_A.id
        ? project.enpalReport.solarPanelAttachment_A.id
        : null;
    project.enpalReport.solarPanelAttachment_B1 =
      project.enpalReport.solarPanelAttachment_B1 && project.enpalReport.solarPanelAttachment_B1.id
        ? project.enpalReport.solarPanelAttachment_B1.id
        : null;
    project.enpalReport.solarPanelAttachment_F =
      project.enpalReport.solarPanelAttachment_F && project.enpalReport.solarPanelAttachment_F.id
        ? project.enpalReport.solarPanelAttachment_F.id
        : null;
    project.enpalReport.solarPanelAttachment_G =
      project.enpalReport.solarPanelAttachment_G && project.enpalReport.solarPanelAttachment_G.id
        ? project.enpalReport.solarPanelAttachment_G.id
        : null;
    project.enpalReport.cableManagementAttachment_D =
      project.enpalReport.cableManagementAttachment_D &&
      project.enpalReport.cableManagementAttachment_D.id
        ? project.enpalReport.cableManagementAttachment_D.id
        : null;
    project.enpalReport.cableManagementAttachment_X =
      project.enpalReport.cableManagementAttachment_X &&
      project.enpalReport.cableManagementAttachment_X.id
        ? project.enpalReport.cableManagementAttachment_X.id
        : null;
    project.enpalReport.inverterAttachment_A =
      project.enpalReport.inverterAttachment_A && project.enpalReport.inverterAttachment_A.id
        ? project.enpalReport.inverterAttachment_A.id
        : null;
    project.enpalReport.inverterAttachment_B =
      project.enpalReport.inverterAttachment_B && project.enpalReport.inverterAttachment_B.id
        ? project.enpalReport.inverterAttachment_B.id
        : null;
    project.enpalReport.inverterAttachment_C =
      project.enpalReport.inverterAttachment_C && project.enpalReport.inverterAttachment_C.id
        ? project.enpalReport.inverterAttachment_C.id
        : null;
    project.enpalReport.inverterAttachment_D =
      project.enpalReport.inverterAttachment_D && project.enpalReport.inverterAttachment_D.id
        ? project.enpalReport.inverterAttachment_D.id
        : null;
    project.enpalReport.inverterAttachment_F =
      project.enpalReport.inverterAttachment_F && project.enpalReport.inverterAttachment_F.id
        ? project.enpalReport.inverterAttachment_F.id
        : null;
    project.enpalReport.inverterAttachment_G2 =
      project.enpalReport.inverterAttachment_G2 && project.enpalReport.inverterAttachment_G2.id
        ? project.enpalReport.inverterAttachment_G2.id
        : null;
    project.enpalReport.inverterAttachment_H =
      project.enpalReport.inverterAttachment_H && project.enpalReport.inverterAttachment_H.id
        ? project.enpalReport.inverterAttachment_H.id
        : null;
    project.enpalReport.storageAttachment_B1 =
      project.enpalReport.storageAttachment_B1 && project.enpalReport.storageAttachment_B1.id
        ? project.enpalReport.storageAttachment_B1.id
        : null;
    project.enpalReport.storageAttachment_B2 =
      project.enpalReport.storageAttachment_B2 && project.enpalReport.storageAttachment_B2.id
        ? project.enpalReport.storageAttachment_B2.id
        : null;
    project.enpalReport.storageAttachment_C1 =
      project.enpalReport.storageAttachment_C1 && project.enpalReport.storageAttachment_C1.id
        ? project.enpalReport.storageAttachment_C1.id
        : null;
    project.enpalReport.storageAttachment_C2 =
      project.enpalReport.storageAttachment_C2 && project.enpalReport.storageAttachment_C2.id
        ? project.enpalReport.storageAttachment_C2.id
        : null;
    project.enpalReport.storageAttachment_D =
      project.enpalReport.storageAttachment_D && project.enpalReport.storageAttachment_D.id
        ? project.enpalReport.storageAttachment_D.id
        : null;
    project.enpalReport.storageAttachment_F =
      project.enpalReport.storageAttachment_F && project.enpalReport.storageAttachment_F.id
        ? project.enpalReport.storageAttachment_F.id
        : null;
    project.enpalReport.storageAttachment_H =
      project.enpalReport.storageAttachment_H && project.enpalReport.storageAttachment_H.id
        ? project.enpalReport.storageAttachment_H.id
        : null;
    project.enpalReport.equipotentialBusBarAttachment_A =
      project.enpalReport.equipotentialBusBarAttachment_A &&
      project.enpalReport.equipotentialBusBarAttachment_A.id
        ? project.enpalReport.equipotentialBusBarAttachment_A.id
        : null;
    project.enpalReport.equipotentialBusBarAttachment_A2 =
      project.enpalReport.equipotentialBusBarAttachment_A2 &&
      project.enpalReport.equipotentialBusBarAttachment_A2.id
        ? project.enpalReport.equipotentialBusBarAttachment_A2.id
        : null;
    project.enpalReport.equipotentialBusBarAttachment_B1 =
      project.enpalReport.equipotentialBusBarAttachment_B1 &&
      project.enpalReport.equipotentialBusBarAttachment_B1.id
        ? project.enpalReport.equipotentialBusBarAttachment_B1.id
        : null;
    project.enpalReport.electricMeterAttachment_B =
      project.enpalReport.electricMeterAttachment_B &&
      project.enpalReport.electricMeterAttachment_B.id
        ? project.enpalReport.electricMeterAttachment_B.id
        : null;
    project.enpalReport.electricMeterAttachment_D =
      project.enpalReport.electricMeterAttachment_D &&
      project.enpalReport.electricMeterAttachment_D.id
        ? project.enpalReport.electricMeterAttachment_D.id
        : null;
    project.enpalReport.electricMeterAttachment_E =
      project.enpalReport.electricMeterAttachment_E &&
      project.enpalReport.electricMeterAttachment_E.id
        ? project.enpalReport.electricMeterAttachment_E.id
        : null;
    project.enpalReport.electricMeterAttachment_H =
      project.enpalReport.electricMeterAttachment_H &&
      project.enpalReport.electricMeterAttachment_H.id
        ? project.enpalReport.electricMeterAttachment_H.id
        : null;
    project.enpalReport.electricMeterAttachment_J =
      project.enpalReport.electricMeterAttachment_J &&
      project.enpalReport.electricMeterAttachment_J.id
        ? project.enpalReport.electricMeterAttachment_J.id
        : null;
    project.enpalReport.electricMeterAttachment_K =
      project.enpalReport.electricMeterAttachment_K &&
      project.enpalReport.electricMeterAttachment_K.id
        ? project.enpalReport.electricMeterAttachment_K.id
        : null;
    project.enpalReport.electricMeterChangeAttachment_1 =
      project.enpalReport.electricMeterChangeAttachment_1 &&
      project.enpalReport.electricMeterChangeAttachment_1.id
        ? project.enpalReport.electricMeterChangeAttachment_1.id
        : null;
    project.enpalReport.electricMeterChangeAttachment_3 =
      project.enpalReport.electricMeterChangeAttachment_3 &&
      project.enpalReport.electricMeterChangeAttachment_3.id
        ? project.enpalReport.electricMeterChangeAttachment_3.id
        : null;
    project.enpalReport.electricMeterChangeAttachment_4 =
      project.enpalReport.electricMeterChangeAttachment_4 &&
      project.enpalReport.electricMeterChangeAttachment_4.id
        ? project.enpalReport.electricMeterChangeAttachment_4.id
        : null;
    project.enpalReport.electricMeterChangeAttachment_5 =
      project.enpalReport.electricMeterChangeAttachment_5 &&
      project.enpalReport.electricMeterChangeAttachment_5.id
        ? project.enpalReport.electricMeterChangeAttachment_5.id
        : null;
    project.enpalReport.electricMeterChangeAttachment_6 =
      project.enpalReport.electricMeterChangeAttachment_6 &&
      project.enpalReport.electricMeterChangeAttachment_6.id
        ? project.enpalReport.electricMeterChangeAttachment_6.id
        : null;
    project.enpalReport.systemPowerOnAttachment_1 =
      project.enpalReport.systemPowerOnAttachment_1 &&
      project.enpalReport.systemPowerOnAttachment_1.id
        ? project.enpalReport.systemPowerOnAttachment_1.id
        : null;
    project.enpalReport.systemPowerOnAttachment_2 =
      project.enpalReport.systemPowerOnAttachment_2 &&
      project.enpalReport.systemPowerOnAttachment_2.id
        ? project.enpalReport.systemPowerOnAttachment_2.id
        : null;
    project.enpalReport.systemPowerOnAttachment_3 =
      project.enpalReport.systemPowerOnAttachment_3 &&
      project.enpalReport.systemPowerOnAttachment_3.id
        ? project.enpalReport.systemPowerOnAttachment_3.id
        : null;

    /** Only send ID's for damageAttachments */
    if (project.enpalReport.damageAttachments) {
      const damageAttachmentIds = [];
      project.enpalReport.damageAttachments.map((damageAttachment) => {
        damageAttachmentIds.push(damageAttachment.id);
      });
      project.enpalReport.damageAttachments = damageAttachmentIds;
    }
    if (project.enpalReport.solarPanelAttachments_C) {
      const solarPanelAttachments_C_Ids = [];
      project.enpalReport.solarPanelAttachments_C.map((attachment) => {
        solarPanelAttachments_C_Ids.push(attachment.id);
      });
      project.enpalReport.solarPanelAttachments_C = solarPanelAttachments_C_Ids;
    }
    if (project.enpalReport.solarPanelAttachments_E1) {
      const solarPanelAttachments_E1_Ids = [];
      project.enpalReport.solarPanelAttachments_E1.map((attachment) => {
        solarPanelAttachments_E1_Ids.push(attachment.id);
      });
      project.enpalReport.solarPanelAttachments_E1 = solarPanelAttachments_E1_Ids;
    }
    if (project.enpalReport.solarPanelAttachments_E2) {
      const solarPanelAttachments_E2_Ids = [];
      project.enpalReport.solarPanelAttachments_E2.map((attachment) => {
        solarPanelAttachments_E2_Ids.push(attachment.id);
      });
      project.enpalReport.solarPanelAttachments_E2 = solarPanelAttachments_E2_Ids;
    }
    if (project.enpalReport.inverterAttachmentsAdditional_F) {
      const inverterAttachmentsAdditional_F_Ids = [];
      project.enpalReport.inverterAttachmentsAdditional_F.map((attachment) => {
        inverterAttachmentsAdditional_F_Ids.push(attachment.id);
      });
      project.enpalReport.inverterAttachmentsAdditional_F = inverterAttachmentsAdditional_F_Ids;
    }
    if (project.enpalReport.storageAttachments_D1) {
      const storageAttachments_D1_Ids = [];
      project.enpalReport.storageAttachments_D1.map((attachment) => {
        storageAttachments_D1_Ids.push(attachment.id);
      });
      project.enpalReport.storageAttachments_D1 = storageAttachments_D1_Ids;
    }
    if (project.enpalReport.additionalImageAttachments) {
      const additionalImageAttachments_Ids = [];
      project.enpalReport.additionalImageAttachments.map((attachment) => {
        additionalImageAttachments_Ids.push(attachment.id);
      });
      project.enpalReport.additionalImageAttachments = additionalImageAttachments_Ids;
    }
    if (project.enpalReport.journalMeasurementsAttachment_A) {
      const journalMeasurementsAttachment_A_Ids = [];
      project.enpalReport.journalMeasurementsAttachment_A.map((attachment) => {
        journalMeasurementsAttachment_A_Ids.push(attachment.id);
      });
      project.enpalReport.journalMeasurementsAttachment_A = journalMeasurementsAttachment_A_Ids;
    }
    if (project.enpalReport.electricMeterChangeAttachments_2) {
      const electricMeterChangeAttachments_2_Ids = [];
      project.enpalReport.electricMeterChangeAttachments_2.map((attachment) => {
        electricMeterChangeAttachments_2_Ids.push(attachment.id);
      });
      project.enpalReport.electricMeterChangeAttachments_2 = electricMeterChangeAttachments_2_Ids;
    }
  }
  if (project.installation) {
    delete project.installation.__typename;
    delete project.installation.createdAt;
    delete project.installation.updatedAt;
    project.installation.electricMeterChangedAt = project.installation.electricMeterChangedAt
      ? project.installation.electricMeterChangedAt
      : null;
    project.installation.operationRegistrationAt = project.installation.operationRegistrationAt
      ? project.installation.operationRegistrationAt
      : null;
  }

  if (project.installation && project.installation.operationRegistrationInstallationAppointment) {
    delete project.installation.operationRegistrationInstallationAppointment.__typename;
    delete project.installation.operationRegistrationInstallationAppointment.id;
  }

  // Meter Cabinet Attachments
  if (project.meterCabinetAttachments) {
    const meterCabinetAttachmentIds = project.meterCabinetAttachments.map(
      (attachment) => attachment.id
    );
    project.meterCabinetAttachments = meterCabinetAttachmentIds;
  }

  // Roof Tile Attachments
  if (project.roofTileAttachments) {
    const roofTileAttachmentIds = project.roofTileAttachments.map((attachment) => attachment.id);
    project.roofTileAttachments = roofTileAttachmentIds;
  }

  // Roof Attachments
  if (project.roofAttachments) {
    const roofAttachmentIds = project.roofAttachments.map((attachment) => attachment.id);
    project.roofAttachments = roofAttachmentIds;
  }
  if (project.presentationAttachments) {
    const presentationAttachmentIds = project.presentationAttachments.map(
      (attachment) => attachment.id
    );
    project.presentationAttachments = presentationAttachmentIds;
  }
  if (project.blueprintAttachments) {
    const blueprintAttachmentIds = project.blueprintAttachments.map((attachment) => attachment.id);
    project.blueprintAttachments = blueprintAttachmentIds;
  }
  if (project.salesMiscAttachments) {
    const salesMiscAttachmentIds = project.salesMiscAttachments.map((attachment) => attachment.id);
    project.salesMiscAttachments = salesMiscAttachmentIds;
  }

  if (project.offerSalesCallOneAttachments) {
    const offerSalesCallOneAttachmentIds = project.offerSalesCallOneAttachments.map(
      (attachment) => attachment.id
    );
    project.offerSalesCallOneAttachments = offerSalesCallOneAttachmentIds;
  }
  if (project.offerSalesCallTwoAttachments) {
    const offerSalesCallTwoAttachmentIds = project.offerSalesCallTwoAttachments.map(
      (attachment) => attachment.id
    );
    project.offerSalesCallTwoAttachments = offerSalesCallTwoAttachmentIds;
  }
  if (project.installationLocationAttachments) {
    const installationLocationAttachmentIds = project.installationLocationAttachments.map(
      (attachment) => attachment.id
    );
    project.installationLocationAttachments = installationLocationAttachmentIds;
  }
  if (project.planningDdAttachments) {
    const planningDdAttachmentIds = project.planningDdAttachments.map(
      (attachment) => attachment.id
    );
    project.planningDdAttachments = planningDdAttachmentIds;
  }

  const itemIds = [];
  if (project.items) {
    project.items.map((item) => {
      itemIds.push({ item: item.item._id, count: item.count });
    });
  }

  /**
   * for text fields differentiate between "" and null: "" are sent to the server as empty value
   * deadlineAt date field: send NULL to server to remove a date
   */
  const payload = {
    consumablesOrderedState: project.consumablesOrderedState,
    consumablesInStockState: project.consumablesInStockState,
    itemPlanningFinishedState: project.itemPlanningFinishedState,
    items: itemIds,
    // customer: project.customer,
    installation: project.installation,
    enpalReport: project.enpalReport,

    /** Reset Build States on save to prevent endless loading icon on errors */
    netRegistrationAttachmentsBuildState: project.netRegistrationAttachmentsBuildState,
    operationRegistrationAttachmentsBuildState: project.operationRegistrationAttachmentsBuildState,
    reportAttachmentsBuildState: project.reportAttachmentsBuildState,
  };
  if (project.solarPlant) {
    Object.assign(payload, {
      solarPlant: {
        solarPanelCount: parseInt(project.solarPlant.solarPanelCount),
        guaranteeCode: project.solarPlant.guaranteeCode,
        qcellsKit: project.solarPlant.qcellsKit,
        extendedCode: project.solarPlant.extendedCode,
        systemNumber: project.solarPlant.systemNumber,
        yearOfConstruction: project.solarPlant.yearOfConstruction,
        emergencyPowerState: project.solarPlant.emergencyPowerState,
        cascadeConnectionState: project.solarPlant.cascadeConnectionState,
        networkSupplyManagementState: project.solarPlant.networkSupplyManagementState,
        networkSupplyManagementPermanentRestrictionState:
          project.solarPlant.networkSupplyManagementPermanentRestrictionState,
        networkSupplyManagementXOverState: project.solarPlant.networkSupplyManagementXOverState,
        netOutputState: project.solarPlant.netOutputState,
        netOutputStates: project.solarPlant.netOutputStates,
        storageCouplingState: project.solarPlant.storageCouplingState,
        electricMeterMountingState: project.solarPlant.electricMeterMountingState,
        yearlyPower: project.solarPlant.yearlyPower,
        houseFuseState: project.solarPlant.houseFuseState,
        electricMeterCombinationState: project.solarPlant.electricMeterCombinationState,
        electricMeterDeregistrationState: project.solarPlant.electricMeterDeregistrationState,
        solarPanel: project.solarPlant.solarPanel ? project.solarPlant.solarPanel.id : null,
        inverter1: project.solarPlant.inverter1 ? project.solarPlant.inverter1.id : null,
        inverter2: project.solarPlant.inverter2 ? project.solarPlant.inverter2.id : null,
        battery: project.solarPlant.battery ? project.solarPlant.battery.id : null,
        energyFlowDirectionSensor: project.solarPlant.energyFlowDirectionSensor
          ? project.solarPlant.energyFlowDirectionSensor.id
          : null,
        wallbox: project.solarPlant.wallbox ? project.solarPlant.wallbox.id : null,
        storageSystem: project.solarPlant.storageSystem
          ? project.solarPlant.storageSystem.id
          : null,
        solarPlantType: project.solarPlant.solarPlantType,
        islandOperationIntended: project.solarPlant.islandOperationIntended,
        motorStartup: project.solarPlant.motorStartup,
        networkSupplyType: project.solarPlant.networkSupplyType,
        measuringConcept: project.solarPlant.measuringConcept,
      },
    });
  }

  if (project.measurement && project.measurement.completed) {
    project.measurement.completed.map((field) => {
      delete field.__typename;
    });
  }
  if (project.enpalReport && project.enpalReport.completed) {
    project.enpalReport.completed.map((field) => {
      delete field.__typename;
    });
  }

  if (project.measurement) {
    Object.assign(payload, {
      measurement: {
        measurementApprovementNote: project.measurement.measurementApprovementNote,
        completed: project.measurement.completed,
        roofType: project.measurement.roofType,
        pitchedRoof: {
          brick:
            project.measurement.pitchedRoof.brick && project.measurement.pitchedRoof.brick.id
              ? project.measurement.pitchedRoof.brick.id
              : null,
          conditionState: project.measurement.pitchedRoof.conditionState,
          bricksPlasteredState: project.measurement.pitchedRoof.bricksPlasteredState,
          cardboardPoppetState: project.measurement.pitchedRoof.cardboardPoppetState,
          notes: project.measurement.pitchedRoof.notes,
          rafterVisibleState: project.measurement.pitchedRoof.rafterVisibleState,
          rafterWidth: project.measurement.pitchedRoof.rafterWidth,
          rafterGap: project.measurement.pitchedRoof.rafterGap,
          rafterNotes: project.measurement.pitchedRoof.rafterNotes,
          eavesHeight1: project.measurement.pitchedRoof.eavesHeight1,
          eavesHeight2: project.measurement.pitchedRoof.eavesHeight2,
          rafterInsulationState: project.measurement.pitchedRoof.rafterInsulationState,
          rafterInsulationNotes: project.measurement.pitchedRoof.rafterInsulationNotes,
          lightningProtectionState: project.measurement.pitchedRoof.lightningProtectionState,
          integrateLightningProtectionState:
            project.measurement.pitchedRoof.integrateLightningProtectionState,
          bricksRemoveableState: project.measurement.pitchedRoof.bricksRemoveableState,
          bricksRemoveableNotes: project.measurement.pitchedRoof.bricksRemoveableNotes,
          roofPitchInDegrees: project.measurement.pitchedRoof.roofPitchInDegrees,
          outerRoofNotes: project.measurement.pitchedRoof.outerRoofNotes,
          bricksConditionState: project.measurement.pitchedRoof.bricksConditionState,
          brickManufacturer: project.measurement.pitchedRoof.brickManufacturer,
          brickType: project.measurement.pitchedRoof.brickType,
          tinBricksRequiredState: project.measurement.pitchedRoof.tinBricksRequiredState,
          bricksNotes: project.measurement.pitchedRoof.bricksNotes,
          roofSidesAssignedState: project.measurement.pitchedRoof.roofSidesAssignedState,
          roofSidesMeasurements: project.measurement.pitchedRoof.roofSidesMeasurements,
          roofSidesShadowsState: project.measurement.pitchedRoof.roofSidesShadowsState,
          roofSidesNotes: project.measurement.pitchedRoof.roofSidesNotes,
          scaffoldingPossibleState: project.measurement.pitchedRoof.scaffoldingPossibleState,
          scaffoldingPossibleNotes: project.measurement.pitchedRoof.scaffoldingPossibleNotes,
          rainPipeMaterialType: project.measurement.pitchedRoof.rainPipeMaterialType,
          rssUsableState: project.measurement.pitchedRoof.rssUsableState,
          rssUsableNotes: project.measurement.pitchedRoof.rssUsableNotes,
          roadBlockRequiredState: project.measurement.pitchedRoof.roadBlockRequiredState,
          overheadLineState: project.measurement.pitchedRoof.overheadLineState,
          roofAccessNotes: project.measurement.pitchedRoof.roofAccessNotes,
        },
        ac: {
          hakLocationState: project.measurement.ac.hakLocationState,
          hakAndZsSameRoomState: project.measurement.ac.hakAndZsSameRoomState,
          hakLockedState: project.measurement.ac.hakLockedState,
          hakToZsDistance: project.measurement.ac.hakToZsDistance,
          fireServiceSwitchPresentState: project.measurement.ac.fireServiceSwitchPresentState,
          hakNotes: project.measurement.ac.hakNotes,
          zsLocationState: project.measurement.ac.zsLocationState,
          newZsRequiredState: project.measurement.ac.newZsRequiredState,
          zsSubDistributionRequiredState: project.measurement.ac.zsSubDistributionRequiredState,
          zsSubDistributionRequiredNotes: project.measurement.ac.zsSubDistributionRequiredNotes,
          netType: project.measurement.ac.netType,
          zsNotes: project.measurement.ac.zsNotes,
          electricMeterCount: project.measurement.ac.electricMeterCount,
          electricMeterList: project.measurement.ac.electricMeterList,
          intermediateMeterRequesteddState: project.measurement.ac.intermediateMeterRequesteddState,
          meterCombinationOrCascadeRequestedState:
            project.measurement.ac.meterCombinationOrCascadeRequestedState,
          electricMeterOptionalNotes: project.measurement.ac.electricMeterOptionalNotes,
          acInstallationLocationState: project.measurement.ac.acInstallationLocationState,
          mateboxToZsDistance: project.measurement.ac.mateboxToZsDistance,
          breakthroughCountState: project.measurement.ac.breakthroughCountState,
          cabelPathDescription: project.measurement.ac.cabelPathDescription,
          locationAndCablePathNotes: project.measurement.ac.locationAndCablePathNotes,
          equipotentialBusBarPresentState: project.measurement.ac.equipotentialBusBarPresentState,
          earthingPresentState: project.measurement.ac.earthingPresentState,
          earthinghNotes: project.measurement.ac.earthinghNotes,
          internetConnectionPresentState: project.measurement.ac.internetConnectionPresentState,
          lanConnectionPresentState: project.measurement.ac.lanConnectionPresentState,
          internetConnectionNotes: project.measurement.ac.internetConnectionNotes,
          wallboxRequiredState: project.measurement.ac.wallboxRequiredState,
          heavyCurrentPresentState: project.measurement.ac.heavyCurrentPresentState,
          wallboxWirelessInstallationPossibleState:
            project.measurement.ac.wallboxWirelessInstallationPossibleState,
          wallboxWirelessInstallationPossibleNotes:
            project.measurement.ac.wallboxWirelessInstallationPossibleNotes,
          wallboxCablePathNotes: project.measurement.ac.wallboxCablePathNotes,
          wallboxNotes: project.measurement.ac.wallboxNotes,
        },
        customer: {
          customerQuestions: project.measurement.customer.customerQuestions,
          customerWorking: project.measurement.customer.customerWorking,
          customerSignature: project.measurement.customer.customerSignature,
        },
        flatRoof: {
          buildingHeight: project.measurement.flatRoof.buildingHeight,
          roofDimension: project.measurement.flatRoof.roofDimension,
          flatRoofPitchInDegrees: project.measurement.flatRoof.flatRoofPitchInDegrees,
          flatRoofingMaterialType: project.measurement.flatRoof.flatRoofingMaterialType,
          flatRoofingMaterialNotes: project.measurement.flatRoof.flatRoofingMaterialNotes,
          atticPresentState: project.measurement.flatRoof.atticPresentState,
          atticPresentNotes: project.measurement.flatRoof.atticPresentNotes,
          flatRoofNotes: project.measurement.flatRoof.flatRoofNotes,
        },
      },
    });
  }

  /** Serviceprotokoll */
  if (project.serviceReport && project.serviceReport.completed) {
    project.serviceReport.completed.map((field) => {
      delete field.__typename;
    });
  }
  if (project.serviceReport) {
    Object.assign(payload, {
      serviceReport: {
        completed: project.serviceReport.completed,
        brickAndUkAssemblyType: project.serviceReport.brickAndUkAssemblyType,
        brickAndUkA2State: project.serviceReport.brickAndUkA2State,
        brickAndUkBState: project.serviceReport.brickAndUkBState,
        railsEarthed: project.serviceReport.railsEarthed,
        brickAndUkOptionalNotes: project.serviceReport.brickAndUkOptionalNotes,
        cableManagementC2State: project.serviceReport.cableManagementC2State,
        cableManagementOptionalNotes: project.serviceReport.cableManagementOptionalNotes,
        solarPaneldOptionalNotes: project.serviceReport.brickAndUkOptionalNotes,
        hakLocationState: project.serviceReport.hakLocationState,
        hakLockedState: project.serviceReport.hakLockedState,
        hakToZsDistance: project.serviceReport.hakToZsDistance,
        hakAndZsSameRoomState: project.serviceReport.hakAndZsSameRoomState,
        zsLocationState: project.serviceReport.zsLocationState,
        additionalSubDistributionState: project.serviceReport.additionalSubDistributionState,
        electricMeterCount: project.serviceReport.electricMeterCount,
        electricMeterList: project.serviceReport.electricMeterList,
        intermediateMeterRequestedState: project.serviceReport.intermediateMeterRequestedState,
        meterCombinationRequestedState: project.serviceReport.meterCombinationRequestedState,
        disassembledMetersOnSiteState: project.serviceReport.disassembledMetersOnSiteState,
        meterCascadeRequestedState: project.serviceReport.meterCascadeRequestedState,
        installationLocationWithInverterAndMateboxState:
          project.serviceReport.installationLocationWithInverterAndMateboxState,
        cablePathDistance: project.serviceReport.cablePathDistance,
        cableSelection: project.serviceReport.cableSelection,
        equipotentialBusBarPresentState: project.serviceReport.equipotentialBusBarPresentState,
        secondaryEquipotentialBusBarPresentState:
          project.serviceReport.secondaryEquipotentialBusBarPresentState,
        potentialGroundRodPresentState: project.serviceReport.potentialGroundRodPresentState,
        earthingSupply16mmState: project.serviceReport.earthingSupply16mmState,
        internetConnectionTypeState: project.serviceReport.internetConnectionTypeState,
        lanConnectionPresentState: project.serviceReport.lanConnectionPresentState,
        qcellsWallboxContractState: project.serviceReport.qcellsWallboxContractState,
        briefingAndDocumentationState: project.serviceReport.briefingAndDocumentationState,
        serviceOptionalNotes: project.serviceReport.serviceOptionalNotes,
        stringPlanAvailableState: project.serviceReport.stringPlanAvailableState,
      },
    });
  }
  /** Qualityprotokoll */
  if (project.qualityReport && project.qualityReport.completed) {
    project.qualityReport.completed.map((field) => {
      delete field.__typename;
    });
  }
  if (project.qualityReport) {
    Object.assign(payload, {
      qualityReport: {
        completed: project.qualityReport.completed,
        properSleeveUsedAtRoofPenetrationState:
          project.qualityReport.properSleeveUsedAtRoofPenetrationState,
        isRoofAccessibleFromInsideState: project.qualityReport.isRoofAccessibleFromInsideState,
        isRoofAccessibleFromInsideNote: project.qualityReport.isRoofAccessibleFromInsideNote,
        properSleeveUsedAtRoofPenetrationNote:
          project.qualityReport.properSleeveUsedAtRoofPenetrationNote,
        underlaySealedWellAtCableEntryState:
          project.qualityReport.underlaySealedWellAtCableEntryState,
        underlayDamagedOrWaterStainsState: project.qualityReport.underlayDamagedOrWaterStainsState,
        labelingOfStringLinesState: project.qualityReport.labelingOfStringLinesState,
        pvStringCableCorrectCrossSectionState:
          project.qualityReport.pvStringCableCorrectCrossSectionState,
        peCableCorrectlySizedState: project.qualityReport.peCableCorrectlySizedState,
        cablesProperlySecuredState: project.qualityReport.cablesProperlySecuredState,
        crimpingProfessionallyDoneState: project.qualityReport.crimpingProfessionallyDoneState,
        correctSelectionOfPvConnectorsOnStringCableState:
          project.qualityReport.correctSelectionOfPvConnectorsOnStringCableState,
        allPlugConnectionsProfessionallyMadeState:
          project.qualityReport.allPlugConnectionsProfessionallyMadeState,
        cablesNeatlyLaidInsideBuildingState:
          project.qualityReport.cablesNeatlyLaidInsideBuildingState,
        spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState:
          project.qualityReport
            .spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterState,
        surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState:
          project.qualityReport.surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryState,
        acceptanceProtocolCustomerSignatureAttachments:
          project.qualityReport.acceptanceProtocolCustomerSignatureAttachments,
        underlaySealedWellAtCableEntryNote:
          project.qualityReport.underlaySealedWellAtCableEntryNote,
        underlayDamagedOrWaterStainsNote: project.qualityReport.underlayDamagedOrWaterStainsNote,
        labelingOfStringLinesNote: project.qualityReport.labelingOfStringLinesNote,
        pvStringCableCorrectCrossSectionNote:
          project.qualityReport.pvStringCableCorrectCrossSectionNote,
        peCableCorrectlySizedNote: project.qualityReport.peCableCorrectlySizedNote,
        cablesProperlySecuredNote: project.qualityReport.cablesProperlySecuredNote,
        crimpingProfessionallyDoneNote: project.qualityReport.crimpingProfessionallyDoneNote,
        correctSelectionOfPvConnectorsOnStringCableNote:
          project.qualityReport.correctSelectionOfPvConnectorsOnStringCableNote,
        allPlugConnectionsProfessionallyMadeNote:
          project.qualityReport.allPlugConnectionsProfessionallyMadeNote,
        cablesNeatlyLaidInsideBuildingNote:
          project.qualityReport.cablesNeatlyLaidInsideBuildingNote,
        spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterNote:
          project.qualityReport
            .spdBoxPresentIfCableInBuildingLongerThan10mInstalledBeforeInverterNote,
        surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryNote:
          project.qualityReport.surgeProtectionBoxProperlyWiredInstallSpdAtRoofEntryNote,
        documentationProjectReportAvailableState:
          project.qualityReport.documentationProjectReportAvailableState,
        documentationProjectReportAvailableNote:
          project.qualityReport.documentationProjectReportAvailableNote,
        specialCustomerRequestsDocumentedState:
          project.qualityReport.specialCustomerRequestsDocumentedState,
        specialCustomerRequestsDocumentedNote:
          project.qualityReport.specialCustomerRequestsDocumentedNote,
        tileTypeAndWidthDocumentedState: project.qualityReport.tileTypeAndWidthDocumentedState,
        tileTypeAndWidthDocumentedNote: project.qualityReport.tileTypeAndWidthDocumentedNote,
        replacementTileInstalledState: project.qualityReport.replacementTileInstalledState,
        replacementTileInstalledNote: project.qualityReport.replacementTileInstalledNote,
        lightningProtectionPresentState: project.qualityReport.lightningProtectionPresentState,
        lightningProtectionPresentNote: project.qualityReport.lightningProtectionPresentNote,
        substructureTensionFreeCheckedState:
          project.qualityReport.substructureTensionFreeCheckedState,
        substructureTensionFreeCheckedNote:
          project.qualityReport.substructureTensionFreeCheckedNote,
        substructureInstalledAsPerReportState:
          project.qualityReport.substructureInstalledAsPerReportState,
        substructureInstalledAsPerReportNote:
          project.qualityReport.substructureInstalledAsPerReportNote,
        eachRailSecuredWithMinTwoHooksState:
          project.qualityReport.eachRailSecuredWithMinTwoHooksState,
        eachRailSecuredWithMinTwoHooksNote:
          project.qualityReport.eachRailSecuredWithMinTwoHooksNote,
        distanceBetweenHooksMoreThan140cmState:
          project.qualityReport.distanceBetweenHooksMoreThan140cmState,
        distanceBetweenHooksMoreThan140cmNote:
          project.qualityReport.distanceBetweenHooksMoreThan140cmNote,
        correctRoofHooksUsedState: project.qualityReport.correctRoofHooksUsedState,
        correctRoofHooksUsedNote: project.qualityReport.correctRoofHooksUsedNote,
        distanceRoofHookToTile35mmState: project.qualityReport.distanceRoofHookToTile35mmState,
        distanceRoofHookToTile35mmNote: project.qualityReport.distanceRoofHookToTile35mmNote,
        minScrewDistanceToRafterEdge24mmState:
          project.qualityReport.minScrewDistanceToRafterEdge24mmState,
        minScrewDistanceToRafterEdge24mmNote:
          project.qualityReport.minScrewDistanceToRafterEdge24mmNote,
        roofHookProperlyFixedToRafterState:
          project.qualityReport.roofHookProperlyFixedToRafterState,
        roofHookProperlyFixedToRafterNote: project.qualityReport.roofHookProperlyFixedToRafterNote,
        overhangObservedState: project.qualityReport.overhangObservedState,
        overhangObservedNote: project.qualityReport.overhangObservedNote,
        moduleFieldProperlyGroundedState: project.qualityReport.moduleFieldProperlyGroundedState,
        moduleFieldProperlyGroundedNote: project.qualityReport.moduleFieldProperlyGroundedNote,
        approvedAuxiliaryRafterUsedState: project.qualityReport.approvedAuxiliaryRafterUsedState,
        approvedAuxiliaryRafterUsedNote: project.qualityReport.approvedAuxiliaryRafterUsedNote,
        rafterWidthMeasuredAndComparedToDocsState:
          project.qualityReport.rafterWidthMeasuredAndComparedToDocsState,
        rafterWidthMeasuredAndComparedToDocsNote:
          project.qualityReport.rafterWidthMeasuredAndComparedToDocsNote,
        noOpenOrDamagedTilesState: project.qualityReport.noOpenOrDamagedTilesState,
        noOpenOrDamagedTilesNote: project.qualityReport.noOpenOrDamagedTilesNote,
        noShiftedOrIncorrectlyLaidTilesState:
          project.qualityReport.noShiftedOrIncorrectlyLaidTilesState,
        noShiftedOrIncorrectlyLaidTilesNote:
          project.qualityReport.noShiftedOrIncorrectlyLaidTilesNote,
        tilesProperlyCleanedState: project.qualityReport.tilesProperlyCleanedState,
        tilesProperlyCleanedNote: project.qualityReport.tilesProperlyCleanedNote,
        correctPanHeadScrewsUsedState: project.qualityReport.correctPanHeadScrewsUsedState,
        correctPanHeadScrewsUsedNote: project.qualityReport.correctPanHeadScrewsUsedNote,
        minRailOverhangAfterEndClamp20mmState:
          project.qualityReport.minRailOverhangAfterEndClamp20mmState,
        minRailOverhangAfterEndClamp20mmNote:
          project.qualityReport.minRailOverhangAfterEndClamp20mmNote,
        distancesToRidgeEavesNotLessThan20cmState:
          project.qualityReport.distancesToRidgeEavesNotLessThan20cmState,
        distancesToRidgeEavesNotLessThan20cmNote:
          project.qualityReport.distancesToRidgeEavesNotLessThan20cmNote,
        moduleClampAreasObservedState: project.qualityReport.moduleClampAreasObservedState,
        moduleClampAreasObservedNote: project.qualityReport.moduleClampAreasObservedNote,
        modulesNoDamagesDirtShadingState: project.qualityReport.modulesNoDamagesDirtShadingState,
        modulesNoDamagesDirtShadingNote: project.qualityReport.modulesNoDamagesDirtShadingNote,
        moduleClampsProperlyTightenedState:
          project.qualityReport.moduleClampsProperlyTightenedState,
        moduleClampsProperlyTightenedNote: project.qualityReport.moduleClampsProperlyTightenedNote,
        thermalSeparationAfter12mModuleFieldState:
          project.qualityReport.thermalSeparationAfter12mModuleFieldState,
        thermalSeparationAfter12mModuleFieldNote:
          project.qualityReport.thermalSeparationAfter12mModuleFieldNote,
        breakthroughProperlySealedState: project.qualityReport.breakthroughProperlySealedState,
        breakthroughProperlySealedNote: project.qualityReport.breakthroughProperlySealedNote,
        distanceLightningProtectionToConductivePartsLessThan05mState:
          project.qualityReport.distanceLightningProtectionToConductivePartsLessThan05mState,
        distanceLightningProtectionToConductivePartsLessThan05mNote:
          project.qualityReport.distanceLightningProtectionToConductivePartsLessThan05mNote,
        gutterUndamagedState: project.qualityReport.gutterUndamagedState,
        gutterUndamagedNote: project.qualityReport.gutterUndamagedNote,
        notBuiltOverRoofOverhangState: project.qualityReport.notBuiltOverRoofOverhangState,
        notBuiltOverRoofOverhangNote: project.qualityReport.notBuiltOverRoofOverhangNote,
        cableProperlyLaidInChimneyState: project.qualityReport.cableProperlyLaidInChimneyState,
        cableProperlyLaidInChimneyNote: project.qualityReport.cableProperlyLaidInChimneyNote,
        distanceToDisturbingSurfaces10cmObservedState:
          project.qualityReport.distanceToDisturbingSurfaces10cmObservedState,
        distanceToDisturbingSurfaces10cmObservedNote:
          project.qualityReport.distanceToDisturbingSurfaces10cmObservedNote,
        cableEntryWithReplacementPlateAndFlexedTileState:
          project.qualityReport.cableEntryWithReplacementPlateAndFlexedTileState,
        cableEntryWithReplacementPlateAndFlexedTileNote:
          project.qualityReport.cableEntryWithReplacementPlateAndFlexedTileNote,
        cablesDoNotRestOnTilesState: project.qualityReport.cablesDoNotRestOnTilesState,
        cablesDoNotRestOnTilesNote: project.qualityReport.cablesDoNotRestOnTilesNote,
        cablesLaidProtectedUnderTilesState:
          project.qualityReport.cablesLaidProtectedUnderTilesState,
        cablesLaidProtectedUnderTilesNote: project.qualityReport.cablesLaidProtectedUnderTilesNote,
        cableEntryOnRoofProperlyDoneState: project.qualityReport.cableEntryOnRoofProperlyDoneState,
        cableEntryOnRoofProperlyDoneNote: project.qualityReport.cableEntryOnRoofProperlyDoneNote,
        cableLaidThroughChimneyState: project.qualityReport.cableLaidThroughChimneyState,
        cableLaidThroughChimneyNote: project.qualityReport.cableLaidThroughChimneyNote,
        cableLaidInProtectiveSleeveAgainstUvRadiationState:
          project.qualityReport.cableLaidInProtectiveSleeveAgainstUvRadiationState,
        cableLaidInProtectiveSleeveAgainstUvRadiationNote:
          project.qualityReport.cableLaidInProtectiveSleeveAgainstUvRadiationNote,
        peCableProperlySecuredState: project.qualityReport.peCableProperlySecuredState,
        peCableProperlySecuredNote: project.qualityReport.peCableProperlySecuredNote,
        documentationProjectReportPresentState:
          project.qualityReport.documentationProjectReportPresentState,
        documentationProjectReportPresentNote:
          project.qualityReport.documentationProjectReportPresentNote,
        customerSpecialRequestsConsideredState:
          project.qualityReport.customerSpecialRequestsConsideredState,
        customerSpecialRequestsConsideredNote:
          project.qualityReport.customerSpecialRequestsConsideredNote,
        flatRooflightningProtectionPresentState:
          project.qualityReport.flatRooflightningProtectionPresentState,
        flatRooflightningProtectionPresentNote:
          project.qualityReport.flatRooflightningProtectionPresentNote,
        properGroundingFlatRoofSystemState:
          project.qualityReport.properGroundingFlatRoofSystemState,
        properGroundingFlatRoofSystemNote: project.qualityReport.properGroundingFlatRoofSystemNote,
        ballastingAccordingToProjectReportState:
          project.qualityReport.ballastingAccordingToProjectReportState,
        ballastingAccordingToProjectReportNote:
          project.qualityReport.ballastingAccordingToProjectReportNote,
        minimumModuleBearingAreaMaintainedState:
          project.qualityReport.minimumModuleBearingAreaMaintainedState,
        minimumModuleBearingAreaMaintainedNote:
          project.qualityReport.minimumModuleBearingAreaMaintainedNote,
        qFlatProperlyConnectedState: project.qualityReport.qFlatProperlyConnectedState,
        qFlatProperlyConnectedNote: project.qualityReport.qFlatProperlyConnectedNote,
        distanceToDisturbingSurfacesMaintainedState:
          project.qualityReport.distanceToDisturbingSurfacesMaintainedState,
        distanceToDisturbingSurfacesMaintainedNote:
          project.qualityReport.distanceToDisturbingSurfacesMaintainedNote,
        flatRoofmoduleClampsProperlyTightenedState:
          project.qualityReport.flatRoofmoduleClampsProperlyTightenedState,
        flatRoofmoduleClampsProperlyTightenedNote:
          project.qualityReport.flatRoofmoduleClampsProperlyTightenedNote,
        modulesNoDamageDirtShadingState: project.qualityReport.modulesNoDamageDirtShadingState,
        modulesNoDamageDirtShadingNote: project.qualityReport.modulesNoDamageDirtShadingNote,
        typeOfCableProtectionOutdoorsState:
          project.qualityReport.typeOfCableProtectionOutdoorsState,
        typeOfCableProtectionOutdoorsNote: project.qualityReport.typeOfCableProtectionOutdoorsNote,
        correctSelectionOfPvConnectorsState:
          project.qualityReport.correctSelectionOfPvConnectorsState,
        correctSelectionOfPvConnectorsNote:
          project.qualityReport.correctSelectionOfPvConnectorsNote,
        cablesDoNotRestOnRoofState: project.qualityReport.cablesDoNotRestOnRoofState,
        cablesDoNotRestOnRoofNote: project.qualityReport.cablesDoNotRestOnRoofNote,
        cableEntryDoneProperlyState: project.qualityReport.cableEntryDoneProperlyState,
        cableEntryDoneProperlyNote: project.qualityReport.cableEntryDoneProperlyNote,
        cablesProtectedFromUvState: project.qualityReport.cablesProtectedFromUvState,
        cablesProtectedFromUvNote: project.qualityReport.cablesProtectedFromUvNote,
        pvStringCableProperlySizedState: project.qualityReport.pvStringCableProperlySizedState,
        pvStringCableProperlySizedNote: project.qualityReport.pvStringCableProperlySizedNote,
        stringCableLabelingState: project.qualityReport.stringCableLabelingState,
        stringCableLabelingNote: project.qualityReport.stringCableLabelingNote,
        peCableProperlySizedState: project.qualityReport.peCableProperlySizedState,
        peCableProperlySizedNote: project.qualityReport.peCableProperlySizedNote,
        flatRoofcablesProperlySecuredState:
          project.qualityReport.flatRoofcablesProperlySecuredState,
        flatRoofcablesProperlySecuredNote: project.qualityReport.flatRoofcablesProperlySecuredNote,
        crimpingProperlyDoneState: project.qualityReport.crimpingProperlyDoneState,
        crimpingProperlyDoneNote: project.qualityReport.crimpingProperlyDoneNote,
        pvSystemLabeledInOrAtDistributionBoardState:
          project.qualityReport.pvSystemLabeledInOrAtDistributionBoardState,
        pvSystemLabeledInOrAtDistributionBoardNote:
          project.qualityReport.pvSystemLabeledInOrAtDistributionBoardNote,
        testReportAvailableWithAllMeasurementsState:
          project.qualityReport.testReportAvailableWithAllMeasurementsState,
        testReportAvailableWithAllMeasurementsNote:
          project.qualityReport.testReportAvailableWithAllMeasurementsNote,
        rcdTypeB300maAvailableInMeterAreaState:
          project.qualityReport.rcdTypeB300maAvailableInMeterAreaState,
        rcdTypeB300maAvailableInMeterAreaNote:
          project.qualityReport.rcdTypeB300maAvailableInMeterAreaNote,
        stringPlanFiledInMeterCabinetState:
          project.qualityReport.stringPlanFiledInMeterCabinetState,
        stringPlanFiledInMeterCabinetNote: project.qualityReport.stringPlanFiledInMeterCabinetNote,
        safetyDistancesMaintainedState: project.qualityReport.safetyDistancesMaintainedState,
        safetyDistancesMaintainedNote: project.qualityReport.safetyDistancesMaintainedNote,
        correctLocationForSystemState: project.qualityReport.correctLocationForSystemState,
        correctLocationForSystemNote: project.qualityReport.correctLocationForSystemNote,
        correctLocationForWallboxState: project.qualityReport.correctLocationForWallboxState,
        correctLocationForWallboxNote: project.qualityReport.correctLocationForWallboxNote,
        customerRequestsDocumentedState: project.qualityReport.customerRequestsDocumentedState,
        customerRequestsDocumentedNote: project.qualityReport.customerRequestsDocumentedNote,
        slsInstalledState: project.qualityReport.slsInstalledState,
        slsInstalledNote: project.qualityReport.slsInstalledNote,
        componentsDamagedState: project.qualityReport.componentsDamagedState,
        componentsDamagedNote: project.qualityReport.componentsDamagedNote,
        crimpingDoneProperlyState: project.qualityReport.crimpingDoneProperlyState,
        crimpingDoneProperlyNote: project.qualityReport.crimpingDoneProperlyNote,
        ussWiredProperlyInCabinetState: project.qualityReport.ussWiredProperlyInCabinetState,
        ussWiredProperlyInCabinetNote: project.qualityReport.ussWiredProperlyInCabinetNote,
        ussBoxWiredProperlyState: project.qualityReport.ussBoxWiredProperlyState,
        ussBoxWiredProperlyNote: project.qualityReport.ussBoxWiredProperlyNote,
        bendingRadiiMaintainedState: project.qualityReport.bendingRadiiMaintainedState,
        bendingRadiiMaintainedNote: project.qualityReport.bendingRadiiMaintainedNote,
        correctWiringOfMateboxState: project.qualityReport.correctWiringOfMateboxState,
        correctWiringOfMateboxNote: project.qualityReport.correctWiringOfMateboxNote,
        correctWiringOfBatteryState: project.qualityReport.correctWiringOfBatteryState,
        correctWiringOfBatteryNote: project.qualityReport.correctWiringOfBatteryNote,
        allComponentsProperlyGroundedState:
          project.qualityReport.allComponentsProperlyGroundedState,
        allComponentsProperlyGroundedNote: project.qualityReport.allComponentsProperlyGroundedNote,
        correctColorCodingAndLabelingState:
          project.qualityReport.correctColorCodingAndLabelingState,
        correctColorCodingAndLabelingNote: project.qualityReport.correctColorCodingAndLabelingNote,
        correctWiringOfPvConnectorsState: project.qualityReport.correctWiringOfPvConnectorsState,
        correctWiringOfPvConnectorsNote: project.qualityReport.correctWiringOfPvConnectorsNote,
        cableDuctProperlySizedAndCutState: project.qualityReport.cableDuctProperlySizedAndCutState,
        cableDuctProperlySizedAndCutNote: project.qualityReport.cableDuctProperlySizedAndCutNote,
        nhHakSelectiveToSlsState: project.qualityReport.nhHakSelectiveToSlsState,
        nhHakSelectiveToSlsNote: project.qualityReport.nhHakSelectiveToSlsNote,
        checkNetworkTypeState: project.qualityReport.checkNetworkTypeState,
        checkNetworkTypeNote: project.qualityReport.checkNetworkTypeNote,
        earthRodConnectedToHpasState: project.qualityReport.earthRodConnectedToHpasState,
        earthRodConnectedToHpasNote: project.qualityReport.earthRodConnectedToHpasNote,
        connectionBetweenHpasAndPasProperlyMadeState:
          project.qualityReport.connectionBetweenHpasAndPasProperlyMadeState,
        connectionBetweenHpasAndPasProperlyMadeNote:
          project.qualityReport.connectionBetweenHpasAndPasProperlyMadeNote,
        insulationProperlyStrippedState: project.qualityReport.insulationProperlyStrippedState,
        insulationProperlyStrippedNote: project.qualityReport.insulationProperlyStrippedNote,
        polarityCheckState: project.qualityReport.polarityCheckState,
        polarityCheckNote: project.qualityReport.polarityCheckNote,
        stringOpenCircuitVoltageState: project.qualityReport.stringOpenCircuitVoltageState,
        stringOpenCircuitVoltageNote: project.qualityReport.stringOpenCircuitVoltageNote,
        shortCircuitCurrentState: project.qualityReport.shortCircuitCurrentState,
        shortCircuitCurrentNote: project.qualityReport.shortCircuitCurrentNote,
        insulationResistanceState: project.qualityReport.insulationResistanceState,
        insulationResistanceNote: project.qualityReport.insulationResistanceNote,
        isConnectorSealingIntactState: project.qualityReport.isConnectorSealingIntactState,
        isConnectorSealingIntactNote: project.qualityReport.isConnectorSealingIntactNote,
        isSurgeProtectionBoxPresenState: project.qualityReport.isSurgeProtectionBoxPresenState,
        isSurgeProtectionBoxPresentNote: project.qualityReport.isSurgeProtectionBoxPresentNote,
        isSurgeProtectionAtHouseEntryState:
          project.qualityReport.isSurgeProtectionAtHouseEntryState,
        isSurgeProtectionAtHouseEntryNote: project.qualityReport.isSurgeProtectionAtHouseEntryNote,
        isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mState:
          project.qualityReport
            .isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mState,
        isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mNote:
          project.qualityReport
            .isLightningProtectionDistanceToConductivePartsOfPVSystemAtLeast0_5mNote,
        isModuleFrameUndamagedState: project.qualityReport.isModuleFrameUndamagedState,
        isModuleFrameUndamagedNote: project.qualityReport.isModuleFrameUndamagedNote,
        isModuleGlassUndamagedState: project.qualityReport.isModuleGlassUndamagedState,
        isModuleGlassUndamagedNote: project.qualityReport.isModuleGlassUndamagedNote,
        isBacksheetUndamagedState: project.qualityReport.isBacksheetUndamagedState,
        isBacksheetUndamagedNote: project.qualityReport.isBacksheetUndamagedNote,
        isModuleNotShadedState: project.qualityReport.isModuleNotShadedState,
        isModuleNotShadedNote: project.qualityReport.isModuleNotShadedNote,
        isConnectorProperlyPluggedState: project.qualityReport.isConnectorProperlyPluggedState,
        isConnectorProperlyPluggedNote: project.qualityReport.isConnectorProperlyPluggedNote,
        isConnectorSealingIntact2State: project.qualityReport.isConnectorSealingIntact2State,
        isConnectorSealingIntact2Note: project.qualityReport.isConnectorSealingIntact2Note,
        isCableRoutedThroughChimneyState: project.qualityReport.isCableRoutedThroughChimneyState,
        isCableRoutedThroughChimneyNote: project.qualityReport.isCableRoutedThroughChimneyNote,
        isChimneySweeperApprovalGivenState:
          project.qualityReport.isChimneySweeperApprovalGivenState,
        isChimneySweeperApprovalGivenNote: project.qualityReport.isChimneySweeperApprovalGivenNote,
        isWallPassageProperlySealedState: project.qualityReport.isWallPassageProperlySealedState,
        isWallPassageProperlySealedNote: project.qualityReport.isWallPassageProperlySealedNote,

        isModuleNotShaded2State: project.qualityReport.isModuleNotShaded2State,
        isModuleNotShaded2Note: project.qualityReport.isModuleNotShaded2Note,
        areModuleClampsNotTwistedState: project.qualityReport.areModuleClampsNotTwistedState,
        areModuleClampsNotTwistedNote: project.qualityReport.areModuleClampsNotTwistedNote,
        isChimneySweeperApprovalGiven2State:
          project.qualityReport.isChimneySweeperApprovalGiven2State,
        isChimneySweeperApprovalGiven2Note:
          project.qualityReport.isChimneySweeperApprovalGiven2Note,
      },
    });
  }
  // console.log(project.measurement);
  // const roughObjSize = JSON.stringify(payload).length / 1024;
  // console.log(roughObjSize);

  if (project.customer) {
    delete project.customer.__typename;
    delete project.customer.createdAt;
    delete project.customer.updatedAt;
  }

  // Preserve existing fields for the "customer" object on the server
  if (project.customer) {
    payload.customer = {
      ...project.customer,
      // Ensure birthdayAt is set to null if not provided
      birthdayAt: project.customer.birthdayAt ? project.customer.birthdayAt : null,
    };
  }
  if (project.sales) {
    delete project.sales.__typename;
    project.sales.inCharge = project.sales.inCharge ? project.sales.inCharge.id : null;
    payload.sales = {
      ...project.sales,
    };
  }
  // console.log(project.sales);

  if (project.deferredState) {
    delete project.deferredState.__typename;
    delete project.deferredState._id;
    delete project.deferredState.createdAt;
    delete project.deferredState.updatedAt;

    payload.deferredState = {
      ...project.deferredState,
    };
  }

  if (project.technicalReview) {
    delete project.technicalReview.__typename;
    delete project.technicalReview._id;
    delete project.technicalReview.createdAt;
    delete project.technicalReview.updatedAt;

    payload.technicalReview = {
      ...project.technicalReview,
    };
  }

  /**
   * new approach: prepare payload and validate by type.
   * merge with existing payload until refactoring is done.
   * @todo derive types from graphql type?
   */

  const typeConfig = {
    employerProjectNumber: 'string',
    powerCompanyProjectNumber: 'string',
    powerCompanyProjectNumber2: 'string',
    powerCompanyProjectNumber3: 'string',
    projectState: 'string',
    projectTypeState: 'string',
    askEmployerCategoryState: 'string',
    projectDescriptionState: 'string',
    mandateState: 'string',
    layoutPlanState: 'string',
    installationImagesState: 'string',
    installationTeamDocumentationState: 'string',
    documentationTransferedToEmployerState: 'string',
    employerInvoiceNumber: 'string',
    technicalReviewDescription: 'string',
    powerCompanyDescription: 'string',
    customerDescription: 'string',
    accountingDescription: 'string',
    measurementVerificationState: 'string',
    measurementApprovementVerificationState: 'string',
    // substructureVerificationState: 'string',
    // dcVerificationState: 'string',
    // acVerificationState: 'string',
    // serviceVerificationState: 'string',
    // serviceApprovementVerificationState: 'string',
    serviceApprovementNote: 'string',
    // acceptanceApprovementVerificationState: 'string',
    acceptanceApprovementNote: 'string',
    // invoiceApprovementVerificationState: 'string',
    invoiceApprovementNote: 'string',
    askEmployerNote: 'string',
    answerFromEmployerNote: 'string',
    accountingAdditionalAcPositions: 'string',

    powerCompanyClearanceAt: 'date',
    deliveryAt: 'date',
    invoiceSentToEmployerAt: 'date',
    employerPayedAt: 'date',
    installationTeamPayedAt: 'date',
    documentationSentToEmployerAt: 'date',
    netRegistrationSentAt: 'date',
    operationRegistrationSentAt: 'date',
    injectionConfirmationReceivedAt: 'date',
    completionMessageSentAt: 'date',
    consumablesDeliveredAt: 'date',

    client: 'id',
    employer: 'id',
    electrician: 'id',
    powerCompany: 'id',
    measurementPointOperator: 'id',
  };

  // console.log(project);
  const validatedPayload = preparePayloadForGraphQL(project, typeConfig);
  // console.log('validatedPayload', validatedPayload);
  const mergedPayload = { ...payload, ...validatedPayload };

  return mergedPayload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Get a smaller subset of project fields
   * @param {*} param0
   * @param {*} payload
   */
  async fetchProjectList({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            projects {
              ...projectListFields
            }
          }
          ${PROJECT_LIST_REQUEST_FIELDS}
        `,
      });
      commit('SET_PROJECTS', response.data.projects);
    } catch (err) {
      throw new Error(err);
    }
  },
  /**
   * Get a smaller subset of project fields filtered
   * @param {*} param0
   * @param {*} payload
   */
  async fetchProjectListFiltered({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            projectsFiltered {
              ...projectListFields
            }
          }
          ${PROJECT_LIST_REQUEST_FIELDS}
        `,
      });
      commit('SET_PROJECTS', response.data.projectsFiltered);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchProjectsByEmployer({ commit, dispatch }, employerId) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectsByEmployer($employerId: ID!) {
            projectsByEmployer(employerId: $employerId) {
              ...projectListFields
            }
          }
          ${PROJECT_LIST_REQUEST_FIELDS}
        `,
        variables: {
          employerId: employerId,
        },
      });
      commit('SET_PROJECTS', response.data.projectsByEmployer);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * fetch all projects belonging to the currently logged in client
   * @param {*} param0
   * @param {*} clientId
   */
  async fetchProjectsByClient({ commit, dispatch }, clientId) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectsByClient($clientId: ID!) {
            projectsByClient(clientId: $clientId) {
              ...projectListFields
            }
          }
          ${PROJECT_LIST_REQUEST_FIELDS}
        `,
        variables: {
          clientId: clientId,
        },
      });
      commit('SET_PROJECTS', response.data.projectsByClient);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Get a project list for the mobile view
   * @deprecated
   * @param {*} param0
   * @param {*} payload
   */
  async fetchProjectListFilteredMobile({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            projectsFilteredMobile {
              ...projectPaginatedListFields
            }
          }
          ${PROJECT_LIST_MOBILE_REQUEST_FIELDS}
        `,
      });
      commit('SET_PROJECTS', response.data.projectsFilteredMobile);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchProjects({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            projects {
              ...projectFields
            }
          }
          ${PROJECT_REQUEST_FIELDS}
        `,
      });
      commit('SET_PROJECTS', response.data.projects);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Fetch a paginated, sorted and filtered project list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchProjectsPaginatedMobile({ commit, dispatch }, { filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectsPaginatedMobile($filters: ProjectFilterInput) {
            projectsPaginatedMobile(filters: $filters) {
              ...projectPaginatedListFields
            }
          }
          ${PROJECT_LIST_MOBILE_REQUEST_FIELDS}
        `,
        variables: {
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });

      commit('SET_PROJECTS', response.data.projectsPaginatedMobile);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  /**
   * Fetch a paginated, sorted and filtered project list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchProjectsPaginated(
    { commit, dispatch },
    { page, pageSize, sortField, sortOrder, filters, projectListType = 'PROJECTS' }
  ) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectsPaginated(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: ProjectFilterInput
          ) {
            projectsPaginated(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              projects {
                ...projectPaginatedListFields
              }
              projectCount
            }
          }
          ${PROJECT_PAGINATED_LIST_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });

      if (projectListType === 'PROJECTS_WITH_ITEMS') {
        commit('SET_PROJECTS_ITEMS_LIST', response.data.projectsPaginated.projects);
        commit('SET_PROJECTS_ITEMS_LIST_COUNT', response.data.projectsPaginated.projectCount);
      } else {
        commit('SET_PROJECTS', response.data.projectsPaginated.projects);
        commit('SET_PROJECT_COUNT', response.data.projectsPaginated.projectCount);
      }
      // commit('ACTIVATE_PROJECT_CACHE');
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  /**
   * Fetch a paginated, sorted and filtered project list in client context
   * @param {*} param0
   * @param {*} payload
   */
  async fetchProjectsPaginatedByClient(
    { commit, dispatch },
    { clientId, page, pageSize, sortField, sortOrder, filters }
  ) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectsPaginatedByClient(
            $clientId: ID!
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: ProjectFilterInput
          ) {
            projectsPaginatedByClient(
              clientId: $clientId
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              projects {
                ...projectPaginatedListFields
              }
              projectCount
            }
          }
          ${PROJECT_PAGINATED_LIST_REQUEST_FIELDS}
        `,
        variables: {
          clientId: clientId,
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });

      // response.data.projectsPaginated.projects.map((project) =>
      //   console.log(project.number, project.customer.lastname)
      // );

      commit('SET_PROJECTS', response.data.projectsPaginatedByClient.projects);
      commit('SET_PROJECT_COUNT', response.data.projectsPaginatedByClient.projectCount);
      // commit('ACTIVATE_PROJECT_CACHE');
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchProjectByNumber({ commit, dispatch }, projectNumber) {
    // console.log('fetchProjectByNumber()', projectNumber);
    try {
      const { data, errors } = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              ...projectFields
            }
          }
          ${PROJECT_REQUEST_FIELDS}
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });

      if (errors) {
        // dispatch('handleErrors', errors);
        return errors;
      }

      commit('ADD_OR_UPDATE_PROJECT', data.projectByNumber);
    } catch (err) {
      // dispatch('handleErrors', errors);
      throw new Error(err);
    }
  },
  async fetchPostsOfProjectByNumber({ commit }, projectNumber) {
    try {
      // console.log('fetchPostsOfProjectByNumber()', projectNumber);

      const { data, errors } = await apolloClient.query({
        query: gql`
          query postsOfProjectByNumber($projectNumber: Int!) {
            postsOfProjectByNumber(projectNumber: $projectNumber) {
              ...projectPostFields
            }
          }
          ${PROJECT_POST_FIELDS}
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });

      if (errors) {
        return errors;
      }

      commit('ADD_OR_UPDATE_POSTS', { projectNumber, posts: data.postsOfProjectByNumber });
    } catch (err) {
      throw new Error(err);
    }
  },

  /** Error handling */
  // handleErrors({ dispatch }, errors) {
  //   // console.dir(errors[0]);

  //   if (errors[0].extensions.code === 'FORBIDDEN') {
  //     router.push('/403');
  //     return;
  //   }

  //   dispatch('message', {
  //     message: errors[0].message,
  //     type: 'danger',
  //     lifetime: 1,
  //   });
  // },

  async fetchNextProject({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              ...projectFields
            }
          }
          ${PROJECT_REQUEST_FIELDS}
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      commit('ADD_OR_UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchProjectsExportUrl({ commit, dispatch }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectsExportUrl {
            projectsExportUrl
          }
        `,
      });
      return response.data.projectsExportUrl;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchProjectMailAttachmentSize({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectMailAttachmentSize(
            $projectNumber: Int!
            $messageBoilerplateType: String!
            $messageAttachmentType: String
          ) {
            projectMailAttachmentSize(
              projectNumber: $projectNumber
              messageBoilerplateType: $messageBoilerplateType
              messageAttachmentType: $messageAttachmentType
            )
          }
        `,
        variables: {
          projectNumber: payload.projectNumber,
          messageBoilerplateType: payload.messageBoilerplateType,
          messageAttachmentType: payload.messageAttachmentType,
        },
      });
      return response.data.projectMailAttachmentSize;
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * @deprecated
   * @param {} param0
   */
  async fetchInstallationTeams({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "InstallationTeam") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_INSTALLATION_TEAMS', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   * @deprecated
   * @param {*} param0
   */
  async fetchInstallationTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "InstallationType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_INSTALLATION_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },
  async fetchProjectsInNeedsAnalysis({ commit }, { page, pageSize, sortField, sortOrder }) {
    console.log('fetchProjectsInNeedsAnalysis aufgerufen');

    try {
      const response = await apolloClient.query({
        query: gql`
          query projectsInNeedsAnalysis(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
          ) {
            projectsInNeedsAnalysis(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
            ) {
              projects {
                number
                customer {
                  lastname
                  firstname
                }
              }
            }
          }
        `,
        variables: { page, pageSize, sortField, sortOrder },
      });
      commit('SET_PROJECTS_IN_NEEDS_ANALYSIS', response.data.projectsInNeedsAnalysis.projects);
      commit('SET_PROJECTS_COUNT', response.data.projectsInNeedsAnalysis.projectCount);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchOriginalInstallationTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "OriginalInstallationType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_ORIGINAL_INSTALLATION_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },
  async fetchSolarSystemTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "SolarSystemType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_SOLAR_SYSTEM_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },
  async fetchProjectAttachmentTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "ProjectAttachmentType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_PROJECT_ATTACHMENT_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   *
   * @param {*} param0
   */
  async fetchDocumentStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "DocumentState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_DOCUMENT_STATES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchProjectStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "ProjectState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_PROJECT_STATES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchNetworkSupplyManagementStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "NetworkSupplyManagementState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_NETWORK_SUPPLY_MANAGEMENT_STATES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchNetworkSupplyManagementPermanentRestrictionStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "NetworkSupplyManagementPermanentRestrictionState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit(
        'SET_NETWORK_SUPPLY_MANAGEMENT_PERMANENT_RESTRICTION_STATES',
        response.data.__type.enumValues
      );
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchNetworkSupplyManagementXOverStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "NetworkSupplyManagementXOverState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_NETWORK_SUPPLY_MANAGEMENT_X_OVER_STATES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchNetOutputStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "NetOutputState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_NET_OUTPUT_STATES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchStorageCouplingStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "StorageCouplingState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_STORAGE_COUPLING_STATES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchElectricMeterMountingStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "ElectricMeterMountingState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_ELECTRIC_METER_MOUNTING_STATES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  async fetchHouseFuseStates({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "HouseFuseState") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_HOUSE_FUSE_STATES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },

  /**
   * create a new project
   * @param {*} param0
   * @param {*} project
   * @returns
   */
  async createProject({ commit, dispatch }, project) {
    const payload = createPayload(project);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createProject($payload: ProjectInput!) {
            createProject(projectInput: $payload) {
              ...projectFields
            }
          }
          ${PROJECT_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Projekt gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_PROJECT', '_new'); // remove temporary _new project
      commit('ADD_OR_UPDATE_PROJECT', response.data.createProject);
      return response.data.createProject.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateProject({ commit, dispatch }, project) {
    const payload = createPayload(project);
    // console.log(payload);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateProject($projectNumber: Int!, $payload: ProjectInput!) {
            updateProject(projectNumber: $projectNumber, projectInput: $payload) {
              ...projectFields
            }
          }
          ${PROJECT_REQUEST_FIELDS}
        `,
        variables: {
          projectNumber: project.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Projekt aktualisiert!',
        type: 'success',
        lifetime: 1,
      });
      commit('ADD_OR_UPDATE_PROJECT', response.data.updateProject);
      // console.log(payload);
    } catch (err) {
      // console.dir(err.graphQLErrors[0]);
      const message = err.graphQLErrors[0].message;
      dispatch('message', {
        message: message,
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * currently not implemented on server side!
   * @param {*} param0
   * @param {*} projectNumber
   */
  async deleteProject({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteProject($projectNumber: Int!) {
            deleteProject(projectNumber: $projectNumber)
          }
        `,
        variables: {
          projectNumber: projectNumber,
        },
      });
      dispatch('message', {
        message: 'Projekt gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_PROJECT', projectNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async sendProjectMail({ commit, dispatch }, { projectId, message }) {
    const payload = {
      subject: message.subject,
      body: message.body,
      type: message.type,
      attachmentType: message.attachmentType,
    };

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation sendProjectMail($projectId: ID!, $payload: MessageInput!) {
            sendProjectMail(projectId: $projectId, messageInput: $payload) {
              id
              number
              initialInformationSentToCustomerAt
              missingDocumentsSentToCustomerAt
              appointmentConfirmationSentAt
              operationRegistrationSentAt
              netRegistrationSentAt
              updatedAt
            }
          }
        `,
        variables: {
          projectId: projectId,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Nachricht verschickt!',
        type: 'success',
        lifetime: 1,
      });

      commit('UPDATE_PROJECT', response.data.sendProjectMail);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Versand!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async sendEmployerApprovalMail({ commit, dispatch }, { projectId, message }) {
    const payload = {
      subject: message.subject,
      body: message.body,
      type: message.type,
    };

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation sendEmployerApprovalMail($projectId: ID!, $payload: MessageInput!) {
            sendEmployerApprovalMail(projectId: $projectId, messageInput: $payload)
          }
        `,
        variables: {
          projectId: projectId,
          payload: payload,
        },
      });
      commit('UPDATE_EMPLOYER_CLEARANCE_AT', {
        id: projectId,
        employerClearanceAt: response.data.sendEmployerApprovalMail,
      });

      dispatch('message', {
        message: 'Nachricht verschickt!',
        type: 'success',
        lifetime: 1,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Versand!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Upload an image belonging to the project
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadProjectAttachment({ commit, dispatch }, payload) {
    // console.log(payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadProjectAttachment($attachmentInput: AttachmentWithTypeInput!) {
            uploadProjectAttachment(attachmentInput: $attachmentInput) {
              ...projectFields
            }
          }
          ${PROJECT_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
            referenceType: payload.parentType,
          },
        },
      });
      console.log(response.data.uploadProjectAttachment);
      commit('UPDATE_PROJECT_ATTACHMENTS', {
        updatedProject: response.data.uploadProjectAttachment,
        referenceType: payload.parentType,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Delete Project Atrachment
   * @param commit
   * @param payload
   */
  async deleteProjectAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteProjectAttachment(
            $projectId: ID!
            $attachmentId: ID!
            $attachmentType: String!
          ) {
            deleteProjectAttachment(
              projectId: $projectId
              attachmentId: $attachmentId
              attachmentType: $attachmentType
            ) {
              ...projectFields
            }
          }
          ${PROJECT_REQUEST_FIELDS}
        `,
        variables: {
          projectId: payload.projectId,
          attachmentId: payload.attachmentId,
          attachmentType: payload.attachmentType,
        },
      });
      // console.log(response)
      // commit('UPDATE_PROJECT', response.data.deleteProjectAttachment);
      commit('UPDATE_PROJECT_ATTACHMENTS', {
        updatedProject: response.data.deleteProjectAttachment,
        referenceType: payload.attachmentType,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async buildNetRegistration({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildNetRegistration($projectId: ID!) {
            buildNetRegistration(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      // dispatch('message', {
      //   message: 'Netzanmeldung wird erstellt!',
      //   type: 'success',
      //   lifetime: 1,
      // });
      commit('UPDATE_NET_REGISTRATION_BUILD_STATE', {
        projectId: projectId,
        netRegistrationAttachmentsBuildState: response.data.buildNetRegistration,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async requestZipAttachment({ commit, dispatch }, { projectId, attachmentType }) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation requestZipAttachment($projectId: ID!, $attachmentType: String!) {
            requestZipAttachment(projectId: $projectId, attachmentType: $attachmentType)
          }
        `,
        variables: {
          projectId: projectId,
          attachmentType: attachmentType,
        },
      });
      return response.data.requestZipAttachment;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async fetchZipBuildState({ commit }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              combinedNetRegistrationAttachmentsZipBuildState
              combinedNetRegistrationAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              netRegistrationAttachmentsGeneratedZipBuildState
              netRegistrationAttachmentsGeneratedZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              operationRegistrationAttachmentsGeneratedZipBuildState
              operationRegistrationAttachmentsGeneratedZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              overviewSchematicAttachmentsGeneratedZipBuildState
              overviewSchematicAttachmentsGeneratedZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              mandateAttachmentsZipBuildState
              mandateAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              overviewSchematicAttachmentsZipBuildState
              overviewSchematicAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              layoutPlanAttachmentsZipBuildState
              layoutPlanAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              projectDescriptionAttachmentsZipBuildState
              projectDescriptionAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              netRegistrationAttachmentsZipBuildState
              netRegistrationAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              operationRegistrationAttachmentsZipBuildState
              operationRegistrationAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              miscAttachmentsZipBuildState
              miscAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              teamACAttachmentsZipBuildState
              teamACAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              teamDCAttachmentsZipBuildState
              teamDCAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              damageAttachmentsZipBuildState
              damageAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              measurementAttachmentsZipBuildState
              measurementAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              operationRegistrationImageAttachmentsZipBuildState
              operationRegistrationImageAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }

              installationTeamDocumentationAttachmentsZipBuildState
              installationTeamDocumentationAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              installationImageAttachmentsZipBuildState
              installationImageAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              offerAttachmentsZipBuildState
              offerAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              invoiceAttachmentsZipBuildState
              invoiceAttachmentsZip {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
              acceptanceReportImageAttachmentsZipBuildState
              acceptanceReportImageAttachmentsZip {
                id
                path
                thumbnail
                filename
                displayFilename
                size
                mimetype
              }
              roofTileAttachments {
                id
                path
                thumbnail
                filename
                displayFilename
                size
                mimetype
              }
              roofAttachments {
                id
                path
                thumbnail
                filename
                displayFilename
                size
                mimetype
              }

              meterCabinetAttachments {
                id
                path
                thumbnail
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async buildOperationRegistration({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildOperationRegistration($projectId: ID!) {
            buildOperationRegistration(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      commit('UPDATE_OPERATION_REGISTRATION_BUILD_STATE', {
        projectId: projectId,
        operationRegistrationAttachmentsBuildState: response.data.buildOperationRegistration,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Start the build of a qcells offer pdf
   * @param {*} param0
   * @param {*} projectId
   */
  async buildProjectOffer({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildProjectOffer($projectId: ID!) {
            buildProjectOffer(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      commit('UPDATE_PROJECT_OFFER_BUILD_STATE', {
        projectId: projectId,
        projectOfferAttachmentsBuildState: response.data.buildProjectOffer,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Start the build of a qcells offer pdf
   * @param {*} param0
   * @param {*} projectId
   */
  async buildConsumablesList({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildConsumablesList($projectId: ID!) {
            buildConsumablesList(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      commit('UPDATE_CONSUMABLES_LIST_BUILD_STATE', {
        projectId: projectId,
        buildConsumablesListBuildState: response.data.buildConsumablesList,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   *
   * @param {*} param0
   * @param {*} projectNumber
   */
  async fetchConsumablesListAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              consumablesListAttachmentsBuildState
              consumablesListAttachments {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber)
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Start the build of a measurement report pdf
   * @param {*} param0
   * @param {*} projectId
   */
  async buildMeasurementReport({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildMeasurementReport($projectId: ID!) {
            buildMeasurementReport(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      commit('UPDATE_MEASUREMENT_REPORT_BUILD_STATE', {
        projectId: projectId,
        measurementReportAttachmentsBuildState:
          response.data.measurementReportAttachmentsBuildState,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  /**
   * Start the build of a service report pdf
   * @param {*} param0
   * @param {*} projectId
   */
  async buildServiceReport({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildServiceReport($projectId: ID!) {
            buildServiceReport(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      commit('UPDATE_SERVICE_REPORT_BUILD_STATE', {
        projectId: projectId,
        serviceReportAttachmentsBuildState: response.data.serviceReportAttachmentsBuildState,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Start the build of a service report pdf
   * @param {*} param0
   * @param {*} projectId
   */
  async buildAssessmentReport({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildAssessmentReport($projectId: ID!) {
            buildAssessmentReport(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      commit('UPDATE_ASSESSMENT_REPORT_BUILD_STATE', {
        projectId: projectId,
        assessmentReportAttachmentsBuildState: response.data.assessmentReportAttachmentsBuildState,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  /**
   * Update build state of the measurement report pdf
   * @param {*} param0
   * @param {*} projectNumber
   */
  async fetchAssessmentReportAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              assessmentReportAttachmentsBuildState
              assessmentReportAttachments {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber);
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },
  /**
   * Update build state of the measurement report pdf
   * @param {*} param0
   * @param {*} projectNumber
   */
  async fetchServiceReportAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              serviceReportAttachmentsBuildState
              serviceReportAttachments {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber)
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Update build state of the measurement report pdf
   * @param {*} param0
   * @param {*} projectNumber
   */
  async fetchMeasurementReportAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              measurementReportAttachmentsBuildState
              measurementReportAttachments {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber)
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async buildOverviewSchematic({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildOverviewSchematic($projectId: ID!) {
            buildOverviewSchematic(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      commit('UPDATE_OVERVIEW_SCHEMATIC_BUILD_STATE', {
        projectId: projectId,
        overviewSchematicAttachmentsBuildState: response.data.buildOverviewSchematic,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async buildReport({ commit, dispatch }, projectId) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation buildReport($projectId: ID!) {
            buildReport(projectId: $projectId)
          }
        `,
        variables: {
          projectId: projectId,
        },
      });
      commit('UPDATE_REPORT_BUILD_STATE', {
        projectId: projectId,
        reportAttachmentsBuildState: response.data.buildReport,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async fetchNetRegistrationAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              netRegistrationAttachmentsBuildState
              netRegistrationAttachmentsGenerated {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber)
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchProjectOfferAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              projectOfferAttachmentsBuildState
              projectOfferAttachments {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber)
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchOperationRegistrationAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              operationRegistrationAttachmentsBuildState
              operationRegistrationAttachmentsGenerated {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber)
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchOverviewSchematicAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              overviewSchematicAttachmentsBuildState
              overviewSchematicAttachmentsGenerated {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber)
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Update Build State for PDF Reports
   * Partial project update!
   * @param {*} param0
   * @param {*} projectNumber
   */
  async fetchReportAttachmentsBuildState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              reportAttachmentsBuildState
              reportAttachments {
                id
                path
                filename
                displayFilename
                size
                mimetype
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber)
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * Start portal registration
   * @param {*} param0
   * @param {*} projectId
   */
  async startPortalRegistration({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation startPortalRegistration($projectNumber: Int!) {
            startPortalRegistration(projectNumber: $projectNumber) {
              id
              number
              referenceId
              type
              state
              powerCompany {
                company
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      commit('UPDATE_PORTAL_REGISTRATION_STATE', {
        projectNumber: projectNumber,
        registrations: response.data.startPortalRegistration,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteRegistration({ commit, dispatch }, { projectNumber, registrationId }) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteRegistration($projectNumber: Int!, $registrationId: ID!) {
            deleteRegistration(projectNumber: $projectNumber, registrationId: $registrationId) {
              id
              number
              referenceId
              type
              state
              powerCompany {
                company
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
          registrationId: registrationId,
        },
      });
      commit('UPDATE_PORTAL_REGISTRATION_STATE', {
        projectNumber: projectNumber,
        registrations: response.data.deleteRegistration,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Update State for portal registrations
   * Partial project update!
   * @param {*} param0
   * @param {*} projectNumber
   * @deprecated
   */
  async fetchPortalRegistrationState({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectByNumber($projectNumber: Int!) {
            projectByNumber(projectNumber: $projectNumber) {
              id
              number
              portalRegistrationState
              registrations {
                id
                number
                referenceId
                type
                state
                createdAt
                powerCompany {
                  company
                }
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectByNumber);
      commit('UPDATE_PROJECT', response.data.projectByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async saveReportSignature({ commit, dispatch }, { projectId, payload, signatureType }) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation saveReportSignature($projectId: ID!, $payload: String, $signatureType: String!) {
            saveReportSignature(
              projectId: $projectId
              payload: $payload
              signatureType: $signatureType
            )
          }
        `,
        variables: {
          projectId: projectId,
          payload: payload,
          signatureType: signatureType,
        },
      });
      return response.data.saveReportSignature;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * Create a post
   * @param commit
   * @param post
   * @returns {Promise<void>}
   */
  async addPostToProject({ commit }, post) {
    const validatedPayload = preparePayloadForGraphQL(post, {
      content: 'string',
      user: 'id',
      project: 'id',
    });
    // console.log('addPostToProject', validatedPayload);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation addPostToProject($payload: CreatePostInput!) {
            addPostToProject(postInput: $payload) {
              id
              content
              createdAt
              updatedAt
              project {
                id
                number
              }
              user {
                id
                number
                firstname
                lastname
                email
                avatar {
                  path
                }
              }
              visits {
                user {
                  number
                  firstname
                  lastname
                  email
                  avatar {
                    path
                  }
                }
                createdAt
                updatedAt
                visitedAt
              }
            }
          }
        `,
        variables: {
          payload: validatedPayload,
        },
      });
      // console.log(validatedPayload.project, response.data.addPostToProject);

      commit('UPDATE_PROJECT_POSTS', {
        projectId: validatedPayload.project,
        posts: response.data.addPostToProject,
      });
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  /**
   * delete a post from a project
   * @param commit
   * @param payload
   * @param id
   * @returns {Promise<void>}
   */
  async deletePostFromProject({ commit }, payload) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deletePostFromProject($postId: ID!, $projectId: ID!) {
            deletePostFromProject(postId: $postId, projectId: $projectId) {
              id
              content
              createdAt
              updatedAt
              project {
                id
                number
              }
              user {
                id
                number
                firstname
                lastname
                email
                avatar {
                  path
                }
              }
              visits {
                user {
                  number
                  firstname
                  lastname
                  email
                  avatar {
                    path
                  }
                }
                createdAt
                updatedAt
                visitedAt
              }
            }
          }
        `,
        variables: {
          postId: payload.postId,
          projectId: payload.projectId,
        },
      });
      // console.log(response)
      commit('UPDATE_PROJECT_POSTS', {
        projectId: payload.projectId,
        posts: response.data.deletePostFromProject,
      });
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  /**
   * fetch if project data is outdated
   *
   * @param {*} param0
   * @param {*} projectNumber
   * @deprecated
   */
  async fetchProjectIsOutdated({ commit, dispatch }, projectNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectIsOutdated($projectNumber: Int!) {
            projectIsOutdated(projectNumber: $projectNumber)
          }
        `,
        variables: {
          projectNumber: projectNumber,
        },
      });
      // console.log(response.data.projectIsOutdated);
      return response.data.projectIsOutdated;
    } catch (err) {
      throw new Error(err);
    }
  },

  /**
   * fetch projectEditingState
   *
   * @param {*} param0
   * @param {*} projectNumber
   * @deprecated
   */
  async fetchProjectEditingState({ commit, dispatch }, projectNumber) {
    // console.log('fetchProjectEditingState()', projectNumber);
    try {
      const response = await apolloClient.query({
        query: gql`
          query projectEditingState($projectNumber: Int!) {
            projectEditingState(projectNumber: $projectNumber) {
              projectIsOutdated
              recentProjectUsers {
                _id
                action
                createdAt
                user {
                  firstname
                  lastname
                  email
                }
              }
            }
          }
        `,
        variables: {
          projectNumber: parseInt(projectNumber),
        },
      });
      // console.log(response.data.projectIsOutdated);
      return response.data.projectEditingState;
    } catch (err) {
      throw new Error(err);
    }
  },

  async updateProjectVerificationState({ commit }, { projectNumber, verificationStateInput }) {
    const validatedPayload = preparePayloadForGraphQL(verificationStateInput, {
      verificationState: 'string',
      verificationStateType: 'string',
    });

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateProjectVerificationState(
            $projectNumber: Int!
            $verificationStateInput: VerificationStateInput!
          ) {
            updateProjectVerificationState(
              projectNumber: $projectNumber
              verificationStateInput: $verificationStateInput
            )
          }
        `,
        variables: {
          verificationStateInput: validatedPayload,
          projectNumber: projectNumber,
        },
      });
      // console.log(response.data.updateProjectVerificationState);

      commit('UPDATE_PROJECT_VERIFICATION_STATE', {
        projectNumber: projectNumber,
        verificationStateType: verificationStateInput.verificationStateType,
        verficationState: response.data.updateProjectVerificationState,
      });
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  /**
   * create a new project object in store and return it
   * @param {*} param0
   * @param {*} project
   */
  async initProject({ commit }) {
    commit('ADD_OR_UPDATE_PROJECT', defaultProject());
  },

  projectLocalUpdate({ commit }, project) {
    commit('ADD_OR_UPDATE_PROJECT', project);
  },

  projectCleanup({ commit }) {
    commit('REMOVE_PROJECT', '_new');
  },

  addItem({ commit }, itemData) {},
};

/**
 * Mutations
 */
const mutations = {
  SET_PROJECTS_ITEMS_LIST: (state, projects) =>
    projects ? (state.projectsItemsList = projects) : (state.projectsItemsList = []),

  SET_PROJECTS_ITEMS_LIST_COUNT: (state, count) => (state.projectsItemsListCount = count),

  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_PROJECTS_IN_NEEDS_ANALYSIS(state, projects) {
    state.projectsInNeedsAnalysis = projects;
  },
  SET_PROJECTS_COUNT(state, count) {
    state.projectCount = count;
  },
  SET_PROJECT_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_PROJECTS: (state, projects) =>
    projects ? (state.projects = projects) : (state.projects = []),
  ADD_PROJECT: (state, project) => state.projects.push(project),
  ADD_OR_UPDATE_PROJECT: (state, updatedProject) => {
    // const existingProject = state.projects.find((project) => project.id === updatedProject.id);
    const projectIndex = state.projects.findIndex((project) => project.id === updatedProject.id);
    // if (existingProject) {
    if (projectIndex !== -1) {
      // Object.assign(existingProject, {}, updatedProject);
      Vue.set(state.projects, projectIndex, updatedProject);
    } else {
      state.projects.push(updatedProject);
    }
  },
  UPDATE_PROJECT: (state, updatedProject) => {
    const project = state.projects.find((project) => project.id === updatedProject.id);
    Object.assign(project, updatedProject);
  },
  UPDATE_EMPLOYER_CLEARANCE_AT: (state, updatedProject) => {
    const project = state.projects.find((project) => project.id === updatedProject.id);
    Vue.set(project, 'employerClearanceAt', updatedProject.employerClearanceAt);
  },
  SET_PROJECT_TYPES: (state, projectTypes) => {
    state.projectTypes = [];
    projectTypes.map((projectType) => {
      state.projectTypes.push(projectType.name);
    });
  },
  ADD_OR_UPDATE_POSTS(state, { projectNumber, posts }) {
    const project = state.projects.find((p) => p.number === projectNumber);
    if (project) {
      project.posts = posts;
    }
  },
  REMOVE_PROJECT: (state, projectId) => {
    const index = state.projects.findIndex((project) => project.id === projectId);
    state.projects.splice(index, 1);
  },
  ADD_EMPTY_PROJECT_CONTACT: (state, updatedProject) => {
    const company = state.projects.find((project) => project.number == updatedProject.number);
    company.contacts.push({});
  },
  REMOVE_PROJECT_CONTACT: (state, { updatedProject, index }) => {
    const company = state.projects.find((project) => project.number == updatedProject.number);
    company.contacts.splice(index, 1);
  },
  SET_DOCUMENT_STATES: (state, documentStates) => {
    state.documentStates = [];
    documentStates.map((documentState) => {
      state.documentStates.push(documentState.name);
    });
  },
  SET_PROJECT_STATES: (state, projectStates) => {
    state.projectStates = [];
    projectStates.map((projectState) => {
      state.projectStates.push(projectState.name);
    });
  },
  SET_NETWORK_SUPPLY_MANAGEMENT_STATES: (state, networkSupplyManagementStates) => {
    state.networkSupplyManagementStates = [];
    networkSupplyManagementStates.map((networkSupplyManagementState) => {
      state.networkSupplyManagementStates.push(networkSupplyManagementState.name);
    });
  },
  SET_NETWORK_SUPPLY_MANAGEMENT_PERMANENT_RESTRICTION_STATES: (
    state,
    networkSupplyManagementPermanentRestrictionStates
  ) => {
    state.networkSupplyManagementPermanentRestrictionStates = [];
    networkSupplyManagementPermanentRestrictionStates.map(
      (networkSupplyManagementPermanentRestrictionState) => {
        state.networkSupplyManagementPermanentRestrictionStates.push(
          networkSupplyManagementPermanentRestrictionState.name
        );
      }
    );
  },
  SET_NETWORK_SUPPLY_MANAGEMENT_X_OVER_STATES: (state, networkSupplyManagementXOverStates) => {
    state.networkSupplyManagementXOverStates = [];
    networkSupplyManagementXOverStates.map((networkSupplyManagementXOverState) => {
      state.networkSupplyManagementXOverStates.push(networkSupplyManagementXOverState.name);
    });
  },
  SET_NET_OUTPUT_STATES: (state, netOutputStates) => {
    state.netOutputStates = [];
    netOutputStates.map((netOutputState) => {
      state.netOutputStates.push(netOutputState.name);
    });
  },
  SET_STORAGE_COUPLING_STATES: (state, storageCouplingStates) => {
    state.storageCouplingStates = [];
    storageCouplingStates.map((storageCouplingState) => {
      state.storageCouplingStates.push(storageCouplingState.name);
    });
  },
  SET_ELECTRIC_METER_MOUNTING_STATES: (state, electricMeterMountingStates) => {
    state.electricMeterMountingStates = [];
    electricMeterMountingStates.map((electricMeterMountingState) => {
      state.electricMeterMountingStates.push(electricMeterMountingState.name);
    });
  },
  SET_HOUSE_FUSE_STATES: (state, houseFuseStates) => {
    state.houseFuseStates = [];
    houseFuseStates.map((houseFuseState) => {
      state.houseFuseStates.push(houseFuseState.name);
    });
  },
  SET_SALUTATIONS: (state, salutations) => {
    state.salutations = [];
    salutations.map((salutation) => {
      state.salutations.push(salutation.name);
    });
  },
  SET_INSTALLATION_TEAMS: (state, installationTeams) => {
    state.installationTeams = [];
    installationTeams.map((installationTeam) => {
      state.installationTeams.push(installationTeam.name);
    });
  },
  SET_INSTALLATION_TYPES: (state, installationTypes) => {
    state.installationTypes = [];
    installationTypes.map((installationType) => {
      state.installationTypes.push(installationType.name);
    });
  },
  SET_ORIGINAL_INSTALLATION_TYPES: (state, originalInstallationTypes) => {
    state.originalInstallationTypes = [];
    originalInstallationTypes.map((originalInstallationType) => {
      state.originalInstallationTypes.push(originalInstallationType.name);
    });
  },
  SET_SOLAR_SYSTEM_TYPES: (state, solarSystemTypes) => {
    state.solarSystemTypes = [];
    solarSystemTypes.map((solarSystemType) => {
      state.solarSystemTypes.push(solarSystemType.name);
    });
  },
  SET_PROJECT_ATTACHMENT_TYPES: (state, projectAttachmentTypes) => {
    state.projectAttachmentTypes = [];
    projectAttachmentTypes.map((projectAttachmentType) => {
      state.projectAttachmentTypes.push(projectAttachmentType.name);
    });
  },

  UPDATE_PROJECT_ATTACHMENTS: (state, { updatedProject, referenceType }) => {
    const project = state.projects.find((project) => project.id === updatedProject.id);
    Vue.set(project, referenceType, updatedProject[referenceType]);
  },
  UPDATE_NET_REGISTRATION_BUILD_STATE: (
    state,
    { projectId, netRegistrationAttachmentsBuildState }
  ) => {
    const project = state.projects.find((project) => project.id === projectId);
    project.netRegistrationAttachmentsBuildState = netRegistrationAttachmentsBuildState;
  },
  UPDATE_OPERATION_REGISTRATION_BUILD_STATE: (
    state,
    { projectId, operationRegistrationAttachmentsBuildState }
  ) => {
    const project = state.projects.find((project) => project.id === projectId);
    project.operationRegistrationAttachmentsBuildState = operationRegistrationAttachmentsBuildState;
  },
  UPDATE_PROJECT_OFFER_BUILD_STATE: (state, { projectId, projectOfferAttachmentsBuildState }) => {
    const project = state.projects.find((project) => project.id === projectId);
    project.projectOfferAttachmentsBuildState = projectOfferAttachmentsBuildState;
  },
  UPDATE_CONSUMABLES_LIST_BUILD_STATE: (
    state,
    { projectId, consumablesListAttachmentsBuildState }
  ) => {
    const project = state.projects.find((project) => project.id === projectId);
    project.consumablesListAttachmentsBuildState = consumablesListAttachmentsBuildState;
  },

  UPDATE_MEASUREMENT_REPORT_BUILD_STATE: (
    state,
    { projectId, measurementReportAttachmentsBuildState }
  ) => {
    const project = state.projects.find((project) => project.id === projectId);
    project.measurementReportAttachmentsBuildState = measurementReportAttachmentsBuildState;
  },
  UPDATE_SERVICE_REPORT_BUILD_STATE: (state, { projectId, serviceReportAttachmentsBuildState }) => {
    const project = state.projects.find((project) => project.id === projectId);
    if (project) {
      project.serviceReportAttachmentsBuildState = serviceReportAttachmentsBuildState;
    }
  },
  UPDATE_ASSESSMENT_REPORT_BUILD_STATE: (
    state,
    { projectId, assessmentReportAttachmentsBuildState }
  ) => {
    const project = state.projects.find((project) => project.id === projectId);
    if (project) {
      project.assessmentReportAttachmentsBuildState = assessmentReportAttachmentsBuildState;
    }
  },

  UPDATE_OVERVIEW_SCHEMATIC_BUILD_STATE: (
    state,
    { projectId, overviewSchematicAttachmentsBuildState }
  ) => {
    const project = state.projects.find((project) => project.id === projectId);
    project.overviewSchematicAttachmentsBuildState = overviewSchematicAttachmentsBuildState;
  },
  UPDATE_REPORT_BUILD_STATE: (state, { projectId, reportAttachmentsBuildState }) => {
    const project = state.projects.find((project) => project.id === projectId);
    project.reportAttachmentsBuildState = reportAttachmentsBuildState;
  },
  UPDATE_PORTAL_REGISTRATION_STATE: (state, { projectNumber, registrations }) => {
    const project = state.projects.find((project) => project.number === projectNumber);
    project.registrations = registrations;
  },
  SET_PROJECT_COUNT: (state, projectCount) => (state.projectCount = projectCount),
  UPDATE_PROJECT_POSTS: (state, { projectId, posts }) => {
    const project = state.projects.find((project) => project.id === projectId);
    project.posts = posts;
  },
  UPDATE_PROJECT_VERIFICATION_STATE: (
    state,
    { projectNumber, verificationStateType, verficationState }
  ) => {
    const project = state.projects.find((project) => project.number === projectNumber);
    project[verificationStateType] = verficationState;
  },
};

/**
 * Getters
 */
const getters = {
  getProjectsItemsList: (state) => state.projectsItemsList,
  getProjectsItemsListCount: (state) => state.projectsItemsListCount,
  getProjects: (state) => {
    // map latest installation appointment to first level
    state.projects.map((project) => {
      project.solarPlant.solarPlantOverallPower = project.solarPlant.solarPlantOverallPower
        ? project.solarPlant.solarPlantOverallPower
        : solarPlantOverallPower(project);
      project.initialInformationSent = project.initialInformationSentToCustomerAt ? 'YES' : 'NO';
    });

    return state.projects;
  },
  getProjectsSortedByLastname: (state) => {
    const projects = state.projects;
    return projects.sort((a, b) => {
      if (!a.customer.lastname || !b.customer.lastname) {
        return 0;
      }
      var nameA = a.customer.lastname.toLowerCase(); // Groß-/Kleinschreibung ignorieren
      var nameB = b.customer.lastname.toLowerCase(); // Groß-/Kleinschreibung ignorieren
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  },
  getDocumentStates: (state) => state.documentStates,
  getProjectStates: (state) => state.projectStates,
  getNetworkSupplyManagementStates: (state) => state.networkSupplyManagementStates,
  getNetworkSupplyManagementPermanentRestrictionStates: (state) =>
    state.networkSupplyManagementPermanentRestrictionStates,
  getNetworkSupplyManagementXOverStates: (state) => state.networkSupplyManagementXOverStates,
  getNetOutputStates: (state) => state.netOutputStates,
  getStorageCouplingStates: (state) => state.storageCouplingStates,
  getElectricMeterMountingStates: (state) => state.electricMeterMountingStates,
  getHouseFuseStates: (state) => state.houseFuseStates,
  getInstallationTeams: (state) => state.installationTeams,
  getInstallationTypes: (state) => state.installationTypes,
  getOriginalInstallationTypes: (state) => state.originalInstallationTypes,
  getSolarSystemTypes: (state) => state.solarSystemTypes,
  getProjectAttachmentTypes: (state) => state.projectAttachmentTypes,
  getProject: (state) => (projectId) => state.projects.find((project) => project.id == projectId),
  getProjectsInNeedsAnalysis: (state) => state.projectsInNeedsAnalysis,
  // getProjectsInNeedsAnalysisCount: (state) => state.projectsInNeedsAnalysis.length,

  getProjectByNumber: (state) => (projectNumber) => {
    const project = state.projects.find((project) => project.number == projectNumber);
    return project;
  },
  getProjectCount: (state) => state.projectCount,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
