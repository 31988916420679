import gql from 'graphql-tag';
import apolloClient from '../apollo';
import Vue from 'vue';

/**
 * State
 */
const defaultEmployee = () => {
  return {
    _id: '_new',
    number: '_new',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    title: '',
    birthName: '',
    activeState: 'ACTIVE',
    placeOfBirth: '',
    countryOfOrigin: '',
    maritialStatus: 'SINGLE',
    gender: 'MALE',
    vacation: null,
    dateOfBirth: null,
    academicEnAt: null,
    academicStartAt: null,
    achievementSchoolDegree: null,
    institution: '',
    users: [],
    children: [],
    nationality: '',
    motherTongue: '',
    street: '',
    zip: '',
    addition: '',
    city: '',
    mobilePhone: '',
    street2: '',
    zip2: '',
    addition2: '',
    vacationDays: '',
    district: '',
    district2: '',
    highestDegreeSchool: '',
    highestSchoolDegree: '',
    city2: '',
    sortCode: '',
    bic: '',
    iban: '',
    nameOfBank: '',
    altAccHolder: '',
    bankAccountNumber: '',
    emergencyPhone: '',
    emergencyContact: '',
    achievement: null,
    privateHealthInsurance: '',
    healthInsurance: '',
    socialSecurityNr: '',
    taxNumber: '',
    phone2: '',
    lastHealthInsurance: '',
    languagesOfCommunication: '',
    severeDisability: '',
    disabilityDegree: '',
    costCenter: '',
    internalNumber: '',
  };
};

const getDefaultState = () => {
  return {
    employees: [],
    employeeCount: 0,
    employeeAttachmentTypes: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an employee
 * used for all queries/mutations which return an employee
 * @type {DocumentNode}
 */
const EMPLOYEE_REQUEST_FIELDS = gql`
  fragment employeeFields on Employee {
    _id
    number
    firstname
    highestDegreeSchool
    vacation
    highestSchoolDegree
    lastname
    email
    phone
    title
    birthName
    academicEnAt
    academicStartAt
    achievement
    dateOfBirth
    achievementSchoolDegree
    activeState
    institution
    placeOfBirth
    countryOfOrigin
    maritialStatus
    nationality
    motherTongue
    street
    zip
    addition
    city
    mobilePhone
    street2
    zip2
    healthInsurance
    costCenter
    internalNumber
    lastHealthInsurance
    bankAccountNumber
    emergencyPhone
    emergencyContact
    privateHealthInsurance
    socialSecurityNr
    taxNumber
    addition2
    district
    district2
    city2
    phone2
    vacationDays
    sortCode
    bic
    iban
    nameOfBank
    altAccHolder
    languagesOfCommunication
    gender
    severeDisability
    disabilityDegree
    childrenAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    disabilityAttachments {
      id
      path
      thumbnail
      filename
      displayFilename
      size
      mimetype
    }
    users {
      id
      number
      firstname
      lastname
      email
    }
    children {
      firstName
      lastName
      birthDate
    }
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} employee
 */
const createPayload = (employee) => {
  /** Only send ID's for users */
  if (employee.users) {
    const ids = [];
    employee.users.map((user) => {
      ids.push(user.id);
    });
    employee.users = ids;
  }
  employee.children =
    employee.children && employee.children.length > 0
      ? employee.children.map((child) => ({
          firstName: child.firstName,
          lastName: child.lastName,
          birthDate: child.birthDate ? child.birthDate : null,
        }))
      : [];

  const payload = {
    ...(employee.costCenter ? { costCenter: employee.costCenter } : {}),
    ...(employee.internalNumber ? { internalNumber: employee.internalNumber } : {}),
    ...(employee.vacationDays ? { vacationDays: employee.vacationDays } : {}),
    ...(employee.vacation ? { vacation: employee.vacation } : {}),
    ...(employee.achievement ? { achievement: employee.achievement } : {}),
    ...(employee.academicStartAt ? { academicStartAt: employee.academicStartAt } : {}),
    ...(employee.academicEnAt ? { academicEnAt: employee.academicEnAt } : {}),
    ...(employee.institution ? { institution: employee.institution } : {}),
    ...(employee.highestSchoolDegree ? { highestSchoolDegree: employee.highestSchoolDegree } : {}),
    ...(employee.highestDegreeSchool ? { highestDegreeSchool: employee.highestDegreeSchool } : {}),
    ...(employee.firstname ? { firstname: employee.firstname } : {}),
    ...(employee.lastname ? { lastname: employee.lastname } : {}),
    ...(employee.title ? { title: employee.title } : {}),
    ...(employee.severeDisability ? { severeDisability: employee.severeDisability } : {}),
    ...(employee.disabilityDegree ? { disabilityDegree: employee.disabilityDegree } : {}),
    ...(employee.birthName !== null ? { birthName: employee.birthName } : {}),
    ...(employee.dateOfBirth ? { dateOfBirth: employee.dateOfBirth } : {}),
    ...(employee.achievementSchoolDegree
      ? { achievementSchoolDegree: employee.achievementSchoolDegree }
      : {}),
    ...(employee.email !== null ? { email: employee.email } : {}),
    ...(employee.placeOfBirth !== null ? { placeOfBirth: employee.placeOfBirth } : {}),
    ...(employee.countryOfOrigin !== null ? { countryOfOrigin: employee.countryOfOrigin } : {}),
    ...(employee.phone !== null ? { phone: employee.phone } : {}),
    ...(employee.phone !== null ? { phone: employee.phone } : {}),
    ...(employee.activeState !== null ? { activeState: employee.activeState } : {}),
    ...(employee.gender !== null ? { gender: employee.gender } : {}),
    ...(employee.maritialStatus !== null ? { maritialStatus: employee.maritialStatus } : {}),
    ...(employee.motherTongue ? { motherTongue: employee.motherTongue } : {}),
    ...(employee.nationality ? { nationality: employee.nationality } : {}),
    ...(employee.street ? { street: employee.street } : {}),
    ...(employee.zip ? { zip: employee.zip } : {}),
    ...(employee.addition ? { addition: employee.addition } : {}),
    ...(employee.city ? { city: employee.city } : {}),
    ...(employee.mobilePhone ? { mobilePhone: employee.mobilePhone } : {}),
    ...(employee.street2 ? { street2: employee.street2 } : {}),
    ...(employee.zip2 ? { zip2: employee.zip2 } : {}),
    ...(employee.addition2 ? { addition2: employee.addition2 } : {}),
    ...(employee.district2 ? { district2: employee.district2 } : {}),
    ...(employee.district ? { district: employee.district } : {}),
    ...(employee.city2 ? { city2: employee.city2 } : {}),
    ...(employee.phone2 ? { phone2: employee.phone2 } : {}),
    ...(employee.bankAccountNumber ? { bankAccountNumber: employee.bankAccountNumber } : {}),
    ...(employee.emergencyPhone ? { emergencyPhone: employee.emergencyPhone } : {}),
    ...(employee.emergencyContact ? { emergencyContact: employee.emergencyContact } : {}),
    ...(employee.lastHealthInsurance ? { lastHealthInsurance: employee.lastHealthInsurance } : {}),
    ...(employee.sortCode ? { sortCode: employee.sortCode } : {}),
    ...(employee.bic ? { bic: employee.bic } : {}),
    ...(employee.iban ? { iban: employee.iban } : {}),
    ...(employee.taxNumber ? { taxNumber: employee.taxNumber } : {}),
    ...(employee.nameOfBank ? { nameOfBank: employee.nameOfBank } : {}),
    ...(employee.altAccHolder ? { altAccHolder: employee.altAccHolder } : {}),
    ...(employee.socialSecurityNr ? { socialSecurityNr: employee.socialSecurityNr } : {}),
    ...(employee.privateHealthInsurance
      ? { privateHealthInsurance: employee.privateHealthInsurance }
      : {}),
    ...(employee.healthInsurance ? { healthInsurance: employee.healthInsurance } : {}),
    ...(employee.languagesOfCommunication
      ? { languagesOfCommunication: employee.languagesOfCommunication }
      : {}),
    users: employee.users,
    children: employee.children,
  };

  console.log(payload);
  return payload;
};

/**
 * Actions
 */
const actions = {
  /**
   * Fetch a paginated, sorted and filtered employee list
   * @param {*} param0
   * @param {*} payload
   */
  async fetchEmployees({ commit, dispatch }, { page, pageSize, sortField, sortOrder, filters }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query employees(
            $page: Int!
            $pageSize: Int!
            $sortField: String
            $sortOrder: Int
            $filters: EmployeeFilterInput
          ) {
            employees(
              page: $page
              pageSize: $pageSize
              sortField: $sortField
              sortOrder: $sortOrder
              filters: $filters
            ) {
              employees {
                ...employeeFields
              }
              employeeCount
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortOrder: sortOrder,
          filters: filters,
        },
        fetchPolicy: 'network-only',
      });
      commit('SET_EMPLOYEES', response.data.employees.employees);
      commit('SET_EMPLOYEE_COUNT', response.data.employees.employeeCount);
      // commit('ACTIVATE_EMPLOYEE_CACHE');
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  async fetchEmployeeAttachmentTypes({ commit }) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            __type(name: "EmployeeAttachmentType") {
              name
              enumValues {
                name
              }
            }
          }
        `,
      });
      commit('SET_EMPLOYEE_ATTACHMENT_TYPES', response.data.__type.enumValues);
    } catch (err) {
      // commit('SET_FLASH_MESSAGE', {message: err.message, type: 'is-danger'})
    }
  },
  /**
   * Upload an image belonging to the employee
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async uploadEmployeeAttachment({ commit, dispatch }, payload) {
    // console.log(payload);
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation uploadEmployeeAttachment($attachmentInput: AttachmentWithTypeInput!) {
            uploadEmployeeAttachment(attachmentInput: $attachmentInput) {
              ...employeeFields
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          attachmentInput: {
            file: payload.file,
            referenceId: payload.parentId,
            referenceType: payload.parentType,
          },
        },
      });
      commit('UPDATE_EMPLOYEE_ATTACHMENTS', {
        updatedEmployee: response.data.uploadEmployeeAttachment,
        referenceType: payload.parentType,
      });
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Upload!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  /**
   * Delete Employee Atrachment
   * @param commit
   * @param payload
   */
  async deleteEmployeeAttachment({ commit, dispatch }, payload) {
    // console.log(payload)
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteEmployeeAttachment(
            $employeeId: ID!
            $attachmentId: ID!
            $attachmentType: String!
          ) {
            deleteEmployeeAttachment(
              employeeId: $employeeId
              attachmentId: $attachmentId
              attachmentType: $attachmentType
            ) {
              ...employeeFields
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          employeeId: payload.employeeId,
          attachmentId: payload.attachmentId,
          attachmentType: payload.attachmentType,
        },
      });
      // console.log(response)
      commit('UPDATE_EMPLOYEE', response.data.deleteEmployeeAttachment);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim Löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },
  async fetchEmployeeByNumber({ commit, dispatch }, employeeNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query employeeByNumber($employeeNumber: Int!) {
            employeeByNumber(employeeNumber: $employeeNumber) {
              ...employeeFields
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          employeeNumber: parseInt(employeeNumber),
        },
      });
      commit('ADD_OR_UPDATE_EMPLOYEE', response.data.employeeByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createEmployee({ commit, dispatch }, employee) {
    const payload = createPayload(employee);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createEmployee($payload: EmployeeInput!) {
            createEmployee(employeeInput: $payload) {
              ...employeeFields
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_EMPLOYEE', '_new'); // remove temporary _new employee
      commit('ADD_OR_UPDATE_EMPLOYEE', response.data.createEmployee);
      return response.data.createEmployee.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateEmployee({ commit, dispatch }, employee) {
    const payload = createPayload(employee);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateEmployee($employeeNumber: Int!, $payload: EmployeeInput!) {
            updateEmployee(employeeNumber: $employeeNumber, employeeInput: $payload) {
              ...employeeFields
            }
          }
          ${EMPLOYEE_REQUEST_FIELDS}
        `,
        variables: {
          employeeNumber: employee.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_EMPLOYEE', response.data.updateEmployee);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteEmployee({ commit, dispatch }, employeeNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteEmployee($employeeNumber: Int!) {
            deleteEmployee(employeeNumber: $employeeNumber)
          }
        `,
        variables: {
          employeeNumber: employeeNumber,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_EMPLOYEE', employeeNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new employee object in store and return it
   * @param {*} param0
   * @param {*} employee
   */
  async initEmployee({ commit }) {
    commit('ADD_OR_UPDATE_EMPLOYEE', defaultEmployee());
  },

  employeeLocalUpdate({ commit }, employee) {
    commit('ADD_OR_UPDATE_EMPLOYEE', employee);
  },

  employeeCleanup({ commit }) {
    commit('REMOVE_EMPLOYEE', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_EMPLOYEE_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_EMPLOYEES: (state, employees) =>
    employees ? (state.employees = employees) : (state.employees = []),
  ADD_EMPLOYEE: (state, employee) => state.employees.push(employee),
  ADD_OR_UPDATE_EMPLOYEE: (state, updatedEmployee) => {
    const existingEmployee = state.employees.find(
      (employee) => employee._id === updatedEmployee._id
    );
    if (existingEmployee) {
      Object.assign(existingEmployee, updatedEmployee);
    } else {
      state.employees.push(updatedEmployee);
    }
  },
  UPDATE_EMPLOYEE: (state, updatedEmployee) => {
    const employee = state.employees.find((employee) => employee._id === updatedEmployee._id);
    if (employee) {
      Object.assign(employee, updatedEmployee);
    }
  },
  REMOVE_EMPLOYEE: (state, employeeId) => {
    const index = state.employees.findIndex((employee) => employee._id === employeeId);
    state.employees.splice(index, 1);
  },
  UPDATE_EMPLOYEE_ATTACHMENTS: (state, { updatedEmployee, referenceType }) => {
    const employee = state.employees.find((employee) => employee.id === updatedEmployee.id);
    Vue.set(employee, referenceType, updatedEmployee[referenceType]);
  },
  SET_EMPLOYEE_COUNT: (state, employeeCount) => (state.employeeCount = employeeCount),
  SET_EMPLOYEE_ATTACHMENT_TYPES: (state, employeeAttachmentTypes) => {
    state.employeeAttachmentTypes = [];
    employeeAttachmentTypes.map((employeeAttachmentType) => {
      state.employeeAttachmentTypes.push(employeeAttachmentType.name);
    });
  },
};

/**
 * Getters
 */
const getters = {
  getEmployeeCount: (state) => state.employeeCount,
  getEmployees: (state) => state.employees,
  getEmployee: (state) => (employeeId) =>
    state.employees.find((employee) => employee._id == employeeId),
  getEmployeeByNumber: (state) => (employeeNumber) =>
    state.employees.find((employee) => employee.number == employeeNumber),
  getEmployeeAttachmentTypes: (state) => state.employeeAttachmentTypes,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
